import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataOutlet, DataSource, IDataOutlet, ISheet, IWorkbook, RelativeGridPosition, Sheet, Workbook, WorkbookColumn } from '../models/workbook.model';
import { IWorkbookSchedule } from '../models/schedule.model';
import { environment } from 'src/environments/environment';
import { ParameterType } from 'src/app/shared/models/search-parameter.model';

@Injectable({
	providedIn: 'root',
})
export class WorkbooksService {
	constructor(private httpClient: HttpClient) {}

	getWorkbooks(): Observable<HttpResponse<Array<IWorkbook>>> {
		const url: string = environment.workspaceAPI('workbooks') + '';
		return this.httpClient
			.get<Array<IWorkbook>>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	getWorkbook(workbookId: string): Observable<HttpResponse<IWorkbook>> {
		const url: string = environment.workspaceAPI('workbooks') + '/' + workbookId;
		return this.httpClient
			.get<IWorkbook>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	postWorkbook(workbook: IWorkbook): Observable<HttpResponse<IWorkbook>> {
		const url: string = environment.workspaceAPI('workbooks') + '';
		return this.httpClient
			.post<IWorkbook>(url, workbook, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	postSheet(sheet: ISheet, workbookId: string): Observable<HttpResponse<ISheet>> {
		delete sheet.sheetId;
		const url: string = environment.workspaceAPI('workbooks') + `/${workbookId}/sheets`;
		return this.httpClient
			.post<ISheet>(url, sheet, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	postDataOutlet(dataOutlet: IDataOutlet, sheetId: string): Observable<HttpResponse<IDataOutlet>> {
		delete dataOutlet.dataOutletId;
		const url: string = environment.workspaceAPI('workbooks') + `/sheets/${sheetId}/data-outlets`;
		return this.httpClient
			.post<IDataOutlet>(url, dataOutlet, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	patchWorkbook(workbookId: string, workbook: IWorkbook): Observable<HttpResponse<IWorkbook>> {
		const url: string = environment.workspaceAPI('workbooks') + '/' + workbookId;
		return this.httpClient
			.patch<IWorkbook>(url, workbook, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteWorkbook(workbookId: string): Observable<HttpResponse<Array<IWorkbook>>> {
		const url: string = environment.workspaceAPI('workbooks') + '/' + workbookId;
		return this.httpClient
			.delete<Array<IWorkbook>>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteSheet(sheetId: string): Observable<HttpResponse<ISheet>> {
		const url: string = environment.workspaceAPI('workbooks') + `/sheets/${sheetId}`;
		return this.httpClient
			.delete<ISheet>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteDataOutlet(dataOutletId: string): Observable<HttpResponse<IDataOutlet>> {
		const url: string = environment.workspaceAPI('workbooks') + `/sheets/data-outlets/${dataOutletId}`;
		return this.httpClient
			.delete<IDataOutlet>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	getSchedules(workbookId: string): Observable<HttpResponse<IWorkbookSchedule[]>> {
		const url: string = environment.workspaceAPI('workbooks') + '/' + workbookId + '/schedules';
		return this.httpClient
			.get<Array<IWorkbookSchedule>>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	patchSchedule(schedule: IWorkbookSchedule): Observable<HttpResponse<IWorkbookSchedule>> {
		const url: string = environment.workspaceAPI('workbooks') + '/schedules/' + schedule.scheduleId;
		return this.httpClient
			.patch<IWorkbookSchedule>(url, schedule, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	postSchedule(schedule: IWorkbookSchedule): Observable<HttpResponse<IWorkbookSchedule>> {
		const url: string = environment.workspaceAPI('workbooks') + '/' + schedule.workbookId + '/schedules';
		return this.httpClient
			.post<IWorkbookSchedule>(url, schedule, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	deleteSchedule(scheduleId: string): Observable<HttpResponse<IWorkbookSchedule>> {
		const url: string = environment.workspaceAPI('workbooks') + '/schedules/' + scheduleId;
		return this.httpClient
			.delete<IWorkbookSchedule>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	prepareWorkbookForSave(workbook: Workbook, isPost: boolean): IWorkbook {
		const workbookAPIObject: IWorkbook = {
			name: workbook.name,
			workbookId: workbook.workbookId,
			sheets: workbook.sheets.map((sheet: Sheet, index: number) => this.prepareSheetForSave(sheet, index, isPost)),
		};
		if (isPost) {
			delete workbookAPIObject.workbookId;
		}
		return workbookAPIObject;
	}

	prepareSheetForSave(sheet: Sheet, index: number, isPost: boolean): ISheet {
		const sheetAPIObject: ISheet = {
			sheetId: sheet.sheetId,
			name: sheet.name,
			sortOrderIndex: index,
		};
		if (isPost) {
			delete sheetAPIObject.sheetId;
		}
		this.assignRelativePositions(sheet.dataOutlets);
		sheetAPIObject.dataOutlets = sheet.dataOutlets.map((outlet: DataOutlet) => this.prepareDataOutletForSave(outlet, isPost));
		return sheetAPIObject;
	}

	prepareDataOutletForSave(outlet: DataOutlet, isPost: boolean): IDataOutlet {
		const outletAPIObject: IDataOutlet = {
			dataOutletId: outlet.dataOutletId,
			sheetId: outlet.sheetId,
			dataSource: outlet.dataSource,
			dateRange: outlet.dateRange,
			displayColumns: outlet.displayColumns.map((column: WorkbookColumn) => ({
				binding: column.binding,
				customLabel: column.customLabel,
				currencyFormatting: column.currencyFormatting,
				commaFormatting: column.commaFormatting,
				hidden: column.hidden,
			})),
			sortBy: outlet.sortParameter?.value.split(',')[0],
			sortDirection: outlet.sortParameter?.value.split(',')[1],
			writeOrderIndex: outlet.writeOrderIndex,
			relativeTo: outlet.relativeTo,
			relativePosition: outlet.relativePosition,
			filters: this.searchParamsToFilterObject(outlet),
		};
		if (isPost) {
			delete outletAPIObject.dataOutletId;
		}
		return outletAPIObject;
	}

	assignRelativePositions(outletSet: DataOutlet[], currentWriteIndex: number = 0, relativeTo?: DataOutlet): DataOutlet[] {
		if (outletSet && outletSet.length) {
			outletSet = outletSet.filter((outlet: DataOutlet) => outlet.literalPosition);
			if (!outletSet.length) {
				return [];
			}
			const rootOutlet: DataOutlet = outletSet.reduce((prev: DataOutlet, curr: DataOutlet) =>
				prev.literalPosition.rowTop + prev.literalPosition.colLeft > curr.literalPosition.rowTop + curr.literalPosition.colLeft ? curr : prev
			);
			rootOutlet.writeOrderIndex = currentWriteIndex;
			currentWriteIndex++;
			rootOutlet.relativeTo = relativeTo?.writeOrderIndex ?? null;
			rootOutlet.relativePosition = this.getRelativePosition(rootOutlet, false, false, relativeTo);
			const isPastElements: DataOutlet[] = outletSet
				.filter((filterOutlet: DataOutlet) => this.isPast(rootOutlet, filterOutlet) && !this.isBelow(rootOutlet, filterOutlet))
				.sort((dataOutletA: DataOutlet, dataOutletB: DataOutlet) => dataOutletA.literalPosition.rowTop - dataOutletB.literalPosition.rowTop)
				.sort((dataOutletA: DataOutlet, dataOutletB: DataOutlet) => dataOutletA.literalPosition.colLeft - dataOutletB.literalPosition.colLeft);

			const isBelowElements: DataOutlet[] = outletSet
				.filter((filterOutlet: DataOutlet) => !this.isPast(rootOutlet, filterOutlet) && this.isBelow(rootOutlet, filterOutlet))
				.sort((dataOutletA: DataOutlet, dataOutletB: DataOutlet) => dataOutletA.literalPosition.colLeft - dataOutletB.literalPosition.colLeft)
				.sort((dataOutletA: DataOutlet, dataOutletB: DataOutlet) => dataOutletA.literalPosition.rowTop - dataOutletB.literalPosition.rowTop);

			const rootBlockElements: DataOutlet[] = outletSet.filter(
				(filterOutlet: DataOutlet) => !this.isPast(rootOutlet, filterOutlet) && !this.isBelow(rootOutlet, filterOutlet) && !(filterOutlet === rootOutlet)
			);

			relativeTo = rootOutlet;
			rootBlockElements.forEach((dataOutlet: DataOutlet) => {
				dataOutlet.writeOrderIndex = currentWriteIndex;
				currentWriteIndex++;
				dataOutlet.relativeTo = relativeTo.writeOrderIndex;
				dataOutlet.relativePosition = this.getRelativePosition(dataOutlet, false, false, relativeTo);
			});

			relativeTo = rootOutlet;
			isPastElements.forEach((dataOutlet: DataOutlet) => {
				dataOutlet.writeOrderIndex = currentWriteIndex;
				currentWriteIndex++;
				dataOutlet.relativeTo = relativeTo.writeOrderIndex;
				dataOutlet.relativePosition = this.getRelativePosition(dataOutlet, true, false, relativeTo);
				relativeTo = dataOutlet;
			});
			relativeTo = rootOutlet;
			isBelowElements.forEach((dataOutlet: DataOutlet) => {
				dataOutlet.writeOrderIndex = currentWriteIndex;
				currentWriteIndex++;
				dataOutlet.relativeTo = relativeTo.writeOrderIndex;
				dataOutlet.relativePosition = this.getRelativePosition(dataOutlet, false, true, relativeTo);
				relativeTo = dataOutlet;
			});
			const isPastAndBelowElements: DataOutlet[] = outletSet.filter(
				(filterOutlet: DataOutlet) => this.isPast(rootOutlet, filterOutlet) && this.isBelow(rootOutlet, filterOutlet)
			);
			relativeTo = rootOutlet;
			const remainingDataOutlets: DataOutlet[] = this.assignRelativePositions(isPastAndBelowElements, currentWriteIndex, relativeTo);
			return [rootOutlet, ...isPastElements, ...isBelowElements, ...remainingDataOutlets];
		} else {
			return [];
		}
	}

	private getRelativePosition(dataOutlet: DataOutlet, isPast: boolean, isBelow: boolean, relativeTo?: DataOutlet): RelativeGridPosition {
		if (relativeTo) {
			const relativeTop: number = dataOutlet.literalPosition.rowTop - (isBelow ? relativeTo.literalPosition.rowBottom : relativeTo.literalPosition.rowTop);
			const relativeLeft: number = dataOutlet.literalPosition.colLeft - (isPast ? relativeTo.literalPosition.colRight : relativeTo.literalPosition.colLeft);
			return {
				isPast,
				isBelow,
				left: relativeLeft,
				top: relativeTop,
			};
		} else {
			return {
				top: dataOutlet.literalPosition.rowTop,
				left: dataOutlet.literalPosition.colLeft,
			};
		}
	}

	private isPast(dataOutlet1: DataOutlet, dataOutlet2: DataOutlet): boolean {
		return dataOutlet1.literalPosition.colRight < dataOutlet2.literalPosition.colLeft;
	}

	private isBelow(dataOutlet1: DataOutlet, dataOutlet2: DataOutlet): boolean {
		return dataOutlet1.literalPosition.rowBottom < dataOutlet2.literalPosition.rowTop;
	}

	searchParamsToFilterObject(outlet: DataOutlet): unknown {
		const filtersObject: unknown = {};
		if (outlet.dataSource === DataSource.transactions) {
			if (outlet.tqlExpression) {
				filtersObject['tql'] = outlet.tqlExpression;
			}
		} else if (outlet.dataSource === DataSource.balances) {
			filtersObject[ParameterType.accountId] = outlet.selectedAccountIds;
		}
		return filtersObject;
	}
}
