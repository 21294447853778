import { IWorkbook } from '@trovata/app/features/workbooks/models/workbook.model';
import { CurrentCash } from '../../features/balances/models/current-cash.model';
import { CashPosition } from '../../features/cash-position/models/cash-position.model';
import { IForecast } from '../../features/forecasts/models/forecast-forecast-response.model';
import { ReconciliationReport } from '../../features/reports/models/reconciliation.model';
import { ReportV4 } from '../../features/reports/models/report.model';
import { AccountTargetV3 } from './account-target.model';
import { DeveloperApplication } from '@trovata/app/features/developer-portal/models/developer-application.model';
import { GLTag } from '@trovata/app/features/transactions/models/glTag.model';
import { Tag } from '@trovata/app/features/transactions/models/tag.model';

export class UpdatedEvent {
	itemId: string;
	itemType: ItemType;
	actionType: ActionType;
	data?: any;

	constructor(actionType: ActionType, itemId: string, data?: any) {
		this.actionType = actionType;
		this.itemId = itemId;
		this.data = data;
	}
}

export class AccountUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: AccountTargetV3) {
		super(actionType, itemId, data);
		this.itemType = ItemType.account;
	}
}

export class ReconUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: ReconciliationReport) {
		super(actionType, itemId, data);
		this.itemType = ItemType.recon;
	}
}

export class ReportUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: ReportV4) {
		super(actionType, itemId, data);
		this.itemType = ItemType.report;
	}
}

export class ForecastV3UpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: IForecast) {
		super(actionType, itemId, data);
		this.itemType = ItemType.forecastV3;
	}
}

export class CurrentCashUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: CurrentCash) {
		super(actionType, itemId, data);
		this.itemType = ItemType.currentCash;
	}
}

export class TagUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: Tag) {
		super(actionType, itemId, data);
		this.itemType = ItemType.tag;
	}
}

export class GLTagUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: GLTag) {
		super(actionType, itemId, data);
		this.itemType = ItemType.glTag;
	}
}

export class CashPositionUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: Partial<CashPosition>) {
		super(actionType, itemId, data);
		this.itemType = ItemType.cashPosition;
	}
}

export class WorkbookUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: IWorkbook) {
		super(actionType, itemId, data);
		this.itemType = ItemType.workbook;
	}
}

export class DeveloperPortalApplicationUpdatedEvent extends UpdatedEvent {
	constructor(actionType: ActionType, itemId: string, data?: DeveloperApplication) {
		super(actionType, itemId, data);
		this.itemType = ItemType.developerPortalApplication;
	}
}

export enum ItemType {
	report = 'report',
	recon = 'recon',
	currentCash = 'currentCash',
	tag = 'tag',
	glTag = 'glTag',
	forecastV3 = 'forecastV3',
	cashPosition = 'cashPosition',
	account = 'account',
	workbook = 'workbook',
	developerPortalApplication = 'developerPortalApplication',
}

export enum ActionType {
	delete = 'delete',
	create = 'create',
	update = 'update',
}
