import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { GetGlTagsReponse, GLTag, GLTagAction, GLTagActionBody, GLTagPayload, PutGlAutoPriorityResponse, PostGLTagResponse } from '../models/glTag.model';
import { GLTagUpdatedEvent, ActionType } from '@trovata/app/shared/models/updated-events.model';
import { UpdatedEventsService } from '@trovata/app/shared/services/updated-events.service';

@Injectable({
	providedIn: 'root',
})
export class GLTagService {
	constructor(
		private httpClient: HttpClient,
		private updatedEventsService: UpdatedEventsService
	) {}

	createGLTag(tag: GLTag): Observable<HttpResponse<PostGLTagResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags`;
		const tagPostBody: GLTagPayload = {
			title: tag.title,
			description: tag.description,
			type: tag.type,
			tql: tag.tql,
			glCode1: tag.glCode1,
			glCode2: tag.glCode2,
		};
		return this.httpClient.post<PostGLTagResponse>(url, tagPostBody, {
			observe: 'response',
		});
	}

	editGLTag(tag: GLTag): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${tag.glTagId}`;
		const tagPostBody: GLTagPayload = {
			glTagId: tag.glTagId,
			title: tag.title,
			description: tag.description,
			type: tag.type,
			tql: tag.tql,
			glCode1: tag.glCode1,
			glCode2: tag.glCode2,
		};
		return this.httpClient
			.put<void>(url, tagPostBody, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	deleteGLTag(tag: GLTag): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${tag.glTagId}`;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	getGLTags(): Observable<HttpResponse<GetGlTagsReponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/list`;
		return this.httpClient.post<GetGlTagsReponse>(url, null, {
			observe: 'response',
		});
	}

	putGlAutoPriority(glTagIds: string[]): Observable<HttpResponse<PutGlAutoPriorityResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/priority`;
		return this.httpClient.put<PutGlAutoPriorityResponse>(
			url,
			{
				glTagIds: glTagIds,
			},
			{
				observe: 'response',
			}
		);
	}

	glAutoReTag(days: number): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/retag`;
		return this.httpClient.post<void>(
			url,
			{ priorDays: days },
			{
				observe: 'response',
			}
		);
	}

	actionGlTag(glTagId: string, action: GLTagAction, transactionIds?: string[], tql?: Object, isAll?: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				if (isAll) {
					await firstValueFrom(this.bulkGLTagTransactions(glTagId, action, tql));
				} else {
					await firstValueFrom(this.glTagTransactions(glTagId, action, transactionIds));
				}
				this.updatedEventsService.updateItem(new GLTagUpdatedEvent(ActionType.update, glTagId));
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}

	private glTagTransactions(glTagId: string, action: GLTagAction, transactionIds: string[]): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${glTagId}/transactions`;
		const body: GLTagActionBody = {
			action: action,
			transactions: transactionIds,
		};
		return this.httpClient.patch<void>(url, body, { observe: 'response' });
	}

	private bulkGLTagTransactions(glTagId: string, action: GLTagAction, tql: object): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${glTagId}/transactions/all`;
		const body: GLTagActionBody = {
			action: action,
		};
		if (action === GLTagAction.include && tql) {
			body.tql = {
				type: 'AST',
				expression: tql,
			};
		}
		return this.httpClient.patch<void>(url, body, { observe: 'response' });
	}
}
