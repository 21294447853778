import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { ClearLastCreatedGlTagId, CreateGlTag, DeleteGlTag, PutGlAutoPriority, UpdateGlTag } from '../../store/actions/glTags.action';
import { GlTagsState } from '../../store/state/glTags.state';
import { UpdatedEventsService } from '@trovata/app/shared/services/updated-events.service';
import { ActionType, GLTagUpdatedEvent } from '@trovata/app/shared/models/updated-events.model';
import { GetGlTags } from '../../store/actions/tags.action';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { AnalyticsUserActions } from '@trovata/app/core/utils/analyticsEnums';
import { VitallyService } from '@trovata/app/core/services/vitally.service';
import { GLTag } from '../../models/glTag.model';

@Injectable({
	providedIn: 'root',
})
export class GlTagFacadeService {
	@Select(GlTagsState.glTags) glTags$: Observable<GLTag[]>;
	@Select(GlTagsState.glTagsInFlight) glTagsInFlight$: Observable<boolean>;
	@Select(GlTagsState.lastCreatedGlTagId)
	lastCreatedGlTagId$: Observable<string>;

	constructor(
		private store: Store,
		private updatedEventsService: UpdatedEventsService,
		private vitallyService: VitallyService
	) {}

	async getGlTags(forceCall?: boolean): Promise<GLTag[]> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.waitForInFlightToComplete(this.glTagsInFlight$);
				let tags: GLTag[] = await firstValueFrom(this.glTags$);
				if (!tags || forceCall) {
					await firstValueFrom(this.store.dispatch(new GetGlTags()));
					tags = await firstValidValueFrom(this.glTags$);
				}
				const tagsCopy: GLTag[] = JSON.parse(JSON.stringify(tags));
				resolve(tagsCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	getGlTag(glTagId: string): Promise<GLTag> {
		return new Promise(async (resolve, reject) => {
			try {
				const glTags: GLTag[] = await this.getGlTags();
				const glTag: GLTag = glTags.find((tag: GLTag) => tag.glTagId === glTagId);
				resolve(glTag);
			} catch (error) {
				reject(error);
			}
		});
	}

	createGlTag(glTag: GLTag): Promise<GLTag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateGlTag(glTag)));
				let lastCreatedGlTagId: string;
				this.lastCreatedGlTagId$.subscribe((id: string) => (lastCreatedGlTagId = id));
				const newGlTag: GLTag = await this.getGlTag(lastCreatedGlTagId);
				const newGlTagCopy: GLTag = JSON.parse(JSON.stringify(newGlTag));
				this.updatedEventsService.updateItem(new GLTagUpdatedEvent(ActionType.create, newGlTagCopy.glTagId, newGlTagCopy));
				this.store.dispatch(new ClearLastCreatedGlTagId());

				const analyticsParams: object = {
					glTagId: newGlTag.glTagId,
					name: newGlTag.title,
					type: newGlTag.type,
				};

				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsCreate, analyticsParams);

				resolve(newGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateGlTag(glTag: GLTag): Promise<GLTag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateGlTag(glTag)));
				const updatedGlTag: GLTag = await this.getGlTag(glTag.glTagId);
				const updatedGlTagCopy: GLTag = JSON.parse(JSON.stringify(updatedGlTag));
				this.updatedEventsService.updateItem(new GLTagUpdatedEvent(ActionType.update, updatedGlTag.glTagId, updatedGlTag));
				const analyticsParams: object = {
					glTagId: updatedGlTag.glTagId,
					name: updatedGlTag.title,
					type: updatedGlTag.type,
				};
				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsUpdate, analyticsParams);
				resolve(updatedGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteGlTag(glTag: GLTag): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteGlTag(glTag)));
				this.updatedEventsService.updateItem(new GLTagUpdatedEvent(ActionType.delete, glTag.glTagId));
				const analyticsParams: object = {
					glTagId: glTag.glTagId,
					name: glTag.title,
					type: glTag.type,
				};
				this.vitallyService.userActionWithParams(AnalyticsUserActions.glTagsDelete, analyticsParams);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	putGlAutoPriority(tagIds: string[]): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.store.dispatch(new PutGlAutoPriority(tagIds));
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}

	private waitForInFlightToComplete(selector: Observable<boolean>): Promise<void> {
		return new Promise(resolve => {
			selector.subscribe(async (inFlight: boolean) => {
				if (!inFlight) {
					resolve();
				}
			});
		});
	}
}
