import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { TagType } from 'src/app/features/transactions/models/tag.model';
import { PermissionId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import {
	ClearGlTagsState,
	ClearLastCreatedGlTagId,
	CreateGlTag,
	DeleteGlTag,
	GetGlTags,
	InitGlTagsState,
	PutGlAutoPriority,
	ResetGlTagsState,
	SetLastCreatedGlTagId,
	UpdateGlTag,
} from '../actions/glTags.action';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { GLTagService } from '../../services/glTag.service';
import { GLTag, PostGLTagResponse } from '../../models/glTag.model';
import { TagService } from '../../services/tag.service';

export class GlTagsStateModel {
	glTags: GLTag[];
	glTagsInFlight: boolean;
	isCached: boolean;
	lastCreatedGlTagId: string;
}

@State<GlTagsStateModel>({
	name: 'glTags',
	defaults: {
		glTags: null,
		glTagsInFlight: null,
		isCached: false,
		lastCreatedGlTagId: null,
	},
})
@Injectable()
export class GlTagsState {
	@Selector()
	static glTags(state: GlTagsStateModel): GLTag[] {
		return state.glTags;
	}
	@Selector()
	static lastCreatedGlTagId(state: GlTagsStateModel): string {
		return state.lastCreatedGlTagId;
	}
	@Selector()
	static glTagsInFlight(state: GlTagsStateModel): boolean {
		return state.glTagsInFlight;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private glTagService: GLTagService,
		private tagService: TagService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitGlTagsState)
	async initGlTagsState(context: StateContext<GlTagsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const glTagsStateIsCached: boolean = this.glTagsStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (permissions && appReady) {
						if (permissions.has(PermissionId.readGlTags)) {
							if (glTagsStateIsCached) {
								context.patchState({
									glTags: deserializedState.glTags.glTags,
									isCached: true,
								});
							} else {
								context.dispatch(new GetGlTags());
							}
						} else {
							context.patchState({
								glTags: [],
								isCached: false,
							});
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetGlTags)
	getGlTags(context: StateContext<GlTagsStateModel>, action: GetGlTags) {
		context.patchState({ glTagsInFlight: true });
		return this.glTagService.getGLTags().pipe(
			tap((response: HttpResponse<{ glTags: GLTag[] }>) => {
				const tags: GLTag[] = response.body.glTags || [];
				context.patchState({ glTags: tags, isCached: true, glTagsInFlight: false });
			}),
			catchError(error => {
				context.patchState({ glTags: null, glTagsInFlight: false });
				return throwError(error);
			})
		);
	}

	@Action(CreateGlTag)
	createGlTag(context: StateContext<GlTagsStateModel>, action: CreateGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			const tag: GLTag = action.tag;
			try {
				const createGlTagReponse: HttpResponse<PostGLTagResponse> = await firstValueFrom(this.glTagService.createGLTag(tag));
				if (tag.type === TagType.glTag || tag.type === TagType.glTagAuto) {
					context.patchState({
						lastCreatedGlTagId: createGlTagReponse.body.tagId,
					});
					await firstValueFrom(context.dispatch(new GetGlTags()));
					this.tagService.updateStoreAndFilters(tag.type);
					resolve();
				}
			} catch (error) {
				reject(new Error('Could not create Gl GLTag'));
			}
		});
	}

	@Action(UpdateGlTag)
	updateGlTag(context: StateContext<GlTagsStateModel>, action: UpdateGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const tag: GLTag = action.tag;
				await firstValueFrom(this.glTagService.editGLTag(tag));
				await firstValueFrom(context.dispatch(new GetGlTags()));
				this.updateFilters(tag.type);
				resolve();
			} catch (error) {
				reject(new Error('Could not update Gl GLTag'));
			}
		});
	}

	@Action(DeleteGlTag)
	deleteGlTag(context: StateContext<GlTagsStateModel>, action: DeleteGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const tagToDelete: GLTag = action.tag;
				const state: GlTagsStateModel = context.getState();
				await firstValueFrom(this.glTagService.deleteGLTag(tagToDelete));
				const tags: GLTag[] = state.glTags.filter((tag: GLTag) => tag.glTagId !== action.tag.glTagId);
				context.patchState({ glTags: tags });
				this.updateFilters(tagToDelete.type);
				this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Gl GLTag'));
			}
		});
	}

	@Action(PutGlAutoPriority)
	putGLAutoPriority(context: StateContext<GlTagsStateModel>, action: PutGlAutoPriority) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.glTagService.putGlAutoPriority(action.glTagIds));
				await this.store.dispatch(new GetGlTags());
				resolve();
			} catch (error) {
				reject(new Error('Could not update GLTag auto priority'));
			}
		});
	}

	@Action(ResetGlTagsState)
	resetGlTagsState(context: StateContext<GlTagsStateModel>) {
		context.dispatch(new ClearGlTagsState());
		context.dispatch(new InitGlTagsState());
	}

	@Action(ClearGlTagsState)
	clearGlTagsState(context: StateContext<GlTagsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: GlTagsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ClearLastCreatedGlTagId)
	clearLastCreatedTagId(context: StateContext<GlTagsStateModel>) {
		context.patchState({ lastCreatedGlTagId: null });
	}

	@Action(SetLastCreatedGlTagId)
	setLastCreatedId(context: StateContext<GlTagsStateModel>, action: SetLastCreatedGlTagId) {
		context.patchState({ lastCreatedGlTagId: action.id });
	}

	private glTagsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedGlTagsState: GlTagsStateModel | undefined = deserializedState.glTags;
		if (deserializedGlTagsState && deserializedGlTagsState.glTags) {
			return true;
		} else {
			return false;
		}
	}

	private updateFilters(type: TagType) {
		if (type === TagType.glTag) {
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glTags));
		} else if (type === TagType.glTagAuto) {
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glAutoTags));
		}
		this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
	}
}
