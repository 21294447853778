import { Injectable } from '@angular/core';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { firstValueFrom, forkJoin, map, Observable, Subject, tap } from 'rxjs';
import { Tag } from '../models/tag.model';
import { Transaction } from '../models/transaction.model';
import { TransactionsService } from './transactions.service';
import { GLTag } from '../models/glTag.model';
import { GLCode } from '../models/gl-code.model';

@Injectable({
	providedIn: 'root',
})
export class TransactionsBatchService {
	batchSize: number;
	requestSize: number;

	constructor(private transactionsService: TransactionsService) {
		this.requestSize = 1000;
		this.batchSize = 7;
	}

	getBatchTransactions(
		q?: string,
		positionType?: string,
		params?: SearchParameter[],
		fullAccountNumbers: boolean = false,
		tags: Tag[] = [],
		glTags: GLTag[] = [],
		glCodes: GLCode[] = [],
		tqlJSONExpression?: Object,
		loadingSubject$?: Subject<number>
	): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			loadingSubject$?.next(0);
			try {
				const totalTransactionsCount: number = await this.getTotalTransactionsCount(q, params, tqlJSONExpression);
				const totalRequests: number = Math.ceil(totalTransactionsCount / this.requestSize);
				let transactionsList: Transaction[] = [];

				const requestPayloads: GetTransactionPayload[] = [];
				for (let i = 0; i < totalRequests; i++) {
					const sizeParam: number = this.requestSize * i;
					const payload: GetTransactionPayload = {
						tags: tags,
						glTags: glTags,
						glCodes: glCodes,
						q,
						positionType,
						from: sizeParam,
						params,
						size: this.requestSize,
						fullAccountNumbers,
						tqlJSONExpression,
					};
					requestPayloads.push(payload);
				}

				const numberOfBatchRequests: number = Math.ceil(totalRequests / this.batchSize);
				const batchRequestPayloads: GetTransactionPayload[][] = [];
				for (let j = 0; j < numberOfBatchRequests; j++) {
					batchRequestPayloads.push(requestPayloads.slice(j * this.batchSize, (j + 1) * this.batchSize));
				}
				for (let k = 0; k < batchRequestPayloads.length; k++) {
					const batchResult: Transaction[] = await this.batchTransactionsHelper(batchRequestPayloads[k], loadingSubject$);
					transactionsList = transactionsList.concat(batchResult);
				}
				resolve(transactionsList);
			} catch (error) {
				reject(error);
			}
		});
	}

	private batchTransactionsHelper(payloads: GetTransactionPayload[], loadingSubject$?: Subject<number>): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			let finishedRequests: number = 0;
			try {
				await forkJoin(
					payloads
						.filter((payload: GetTransactionPayload) => payload)
						.map((filteredPayload: GetTransactionPayload) =>
							this.getTransactions(filteredPayload).pipe(
								tap(() => {
									finishedRequests += 1;
									loadingSubject$.next((finishedRequests / payloads.length) * 100);
								})
							)
						)
				).subscribe(results => {
					const allTransactions: Transaction[] = Array.prototype.concat.apply([], results);
					resolve(allTransactions);
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	getTransactions(payload: GetTransactionPayload): Observable<Transaction[]> {
		return this.transactionsService
			.getTransactions(
				payload.tags,
				payload.glTags,
				payload.glCodes,
				payload.q,
				payload.positionType,
				payload.from,
				payload.params,
				payload.size,
				payload.fullAccountNumbers,
				null,
				payload.tqlJSONExpression
			)
			.pipe(map(resp => resp.body.transactions));
	}

	getTotalTransactionsCount(q?: string, params?: SearchParameter[], tqlJSONExpression?: Object): Promise<number> {
		return new Promise(async (resolve, reject) => {
			try {
				const totalTransactions: number = (
					await firstValueFrom(this.transactionsService.getTransactionsSummary(q, null, null, params, null, null, null, tqlJSONExpression))
				).body.totalTransactions;
				resolve(totalTransactions);
			} catch (error) {
				reject(error);
			}
		});
	}
}

export class GetTransactionPayload {
	tags: Tag[];
	glTags: GLTag[];
	glCodes: GLCode[];
	q: string;
	positionType: string;
	from: number;
	params: SearchParameter[];
	size: number;
	fullAccountNumbers: boolean;
	tqlJSONExpression: Object;
}
