import { EditCurrentUserBody, EditCustomerBody, LinkAccountsStatus, SSOConnection } from '../../models/identity.model';

export class InitIdentityState {
	static readonly type: string = '[Identity] InitIdentityState';
}

export class ClearIdentityState {
	static readonly type: string = '[Identity] ClearIdentityState';
}

export class ResetIdentityState {
	static readonly type: string = '[Identity] ResetIdentityState';
}

export class GetCustomer {
	static readonly type: string = '[Identity] GetCustomer';
}

export class GetCurrentUser {
	static readonly type: string = '[Identity] GetCurrentUser';
}

export class EditCustomer {
	static readonly type: string = '[Identity] EditCustomer';
	constructor(public editCustomerBody: EditCustomerBody) {}
}

export class EditCurrentUser {
	static readonly type: string = '[Identity] EditCurrentUser';
	constructor(public editCurrentUserBody: EditCurrentUserBody) {}
}

export class SetLinkAccountsStatus {
	static readonly type: string = '[Identity] SetLinkAccountsStatus';
	constructor(public linkAccountsStatus: LinkAccountsStatus) {}
}
export class GetSSOConnection {
	static readonly type: string = '[Identity] GetSSOConnection';
}
export class CreateSSOConnection {
	static readonly type: string = '[Identity] CreateSSOConnection';
	constructor(public ssoConnection: SSOConnection) {}
}
export class EditSSOConnection {
	static readonly type: string = '[Identity] UpdateSSOConnection';
	constructor(public ssoConnection: SSOConnection) {}
}
export class DeleteSSOConnection {
	static readonly type: string = '[Identity] DeleteSSOConnection';
}
