import { FilterMap, FilterOption } from 'src/app/shared/models/abstract-filter.model';
import { ParameterType, SearchData, SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { Cadence } from '../../reports/models/cadence.model';
import { DateTime } from 'luxon';
import { HistoricalBalance } from 'src/app/shared/models/historical-balance.model';
import { getPeriods, getStartDate } from '../../reports/utils/helpers';
import { AccountTargetV3 } from '@trovata/app/shared/models/account-target.model';
import { SelectionPaneOptions, SelectionPanelType } from '@trovata/app/shared/models/selection-pane.model';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';

export class IWorkbook {
	workbookId?: string;
	name: string;
	sheets?: ISheet[];
	updatedAt?: string;
	createdAt?: string;
}

export class ISheet {
	sheetId?: string;
	workbookId?: string;
	name: string;
	sortOrderIndex: number;
	dataOutlets?: IDataOutlet[];
	updatedAt?: string;
	createdAt?: string;
}

export interface IDataOutlet {
	dataOutletId?: string;
	sheetId?: string;
	filters: IDataOutletFilters;
	dataSource: DataSource;
	dateRange: DateRange;
	sortBy: SortByOption;
	sortDirection: SortDirection;
	displayColumns: WorkbookColumn[];
	relativeTo: number;
	relativePosition: RelativeGridPosition;
	writeOrderIndex: number;
	updatedAt?: string;
	createdAt?: string;
}

export class Workbook {
	workbookId: string;
	name: string;
	sheets: Sheet[];

	constructor(workbook?: IWorkbook, filterMap?: FilterMap, contextDate?: DateTime) {
		this.name = workbook?.name;
		this.workbookId = workbook?.workbookId;
		if (workbook && workbook.sheets && workbook.sheets.length) {
			this.sheets = workbook.sheets.map((sheet: ISheet) => new Sheet(sheet, filterMap, contextDate)).sort((a, b) => a.sortOrderIndex - b.sortOrderIndex);
		} else {
			this.sheets = [new Sheet()];
		}
	}
}

export class Sheet {
	sheetId: string;
	name: string;
	dataOutlets: DataOutlet[];
	sortOrderIndex: number;
	minRowCount: number;
	minColCount: number;

	constructor(sheet?: ISheet, filterMap?: FilterMap, contextDate?: DateTime) {
		this.name = sheet ? sheet.name : 'Sheet1';
		this.sheetId = sheet?.sheetId;
		this.sortOrderIndex = sheet?.sortOrderIndex;
		if (sheet && sheet.dataOutlets && sheet.dataOutlets.length) {
			this.dataOutlets = sheet.dataOutlets.map((dataOutlet: IDataOutlet) => new DataOutlet(dataOutlet, filterMap, contextDate));
		} else {
			this.dataOutlets = [];
		}
	}
}

export class DataOutlet {
	dataOutletId: string;
	sheetId: string;
	calendarSettings: CalendarSettings;
	dataSource: DataSource;
	dateRange: DateRange;
	displayDateRangeOption: string;
	sortParameter?: SearchParameter;
	displayColumns: WorkbookColumn[];
	relativeTo: number;
	writeOrderIndex: number;
	relativePosition?: RelativeGridPosition;
	literalPosition?: LiteralGridPosition;
	selectedAccountIds: string[];
	selectionPaneOptions: SelectionPaneOptions<AccountTargetV3>;
	searchData?: SearchData;
	tqlExpression: object;
	gridData: (string | number)[][];
	isOnGrid: boolean;

	constructor(
		dataOutlet?: IDataOutlet,
		private filterMap?: FilterMap,
		contextDate?: DateTime
	) {
		if (dataOutlet && this.filterMap) {
			this.dataOutletId = dataOutlet.dataOutletId;
			this.dateRange = dataOutlet.dateRange;
			this.dataSource = dataOutlet.dataSource;
			this.displayColumns = dataOutlet.displayColumns;
			this.displayDateRangeOption = dateRangeDisplayOptions.find(option => option.key === dataOutlet.dateRange.dateRangeOption).displayValue;
			this.relativeTo = dataOutlet.relativeTo;
			this.relativePosition = dataOutlet.relativePosition;
			this.writeOrderIndex = dataOutlet.writeOrderIndex;
			this.searchData = new SearchData();
			this.isOnGrid = false;
			this.setDateRangeProperties(contextDate);
			if (dataOutlet.filters && dataOutlet.dataSource === DataSource.transactions) {
				this.setTQLFromFilters(dataOutlet.filters);
			} else if (dataOutlet.filters && dataOutlet.dataSource === DataSource.balances) {
				this.setSelectedAccountsFromFilters(dataOutlet.filters);
			}
			if (dataOutlet.sortBy && dataOutlet.sortDirection) {
				this.setSortParameter(dataOutlet.sortBy, dataOutlet.sortDirection);
			}
		} else {
			this.searchData = new SearchData();
			this.dateRange = {
				dateRangeOption: null,
			};
			this.selectionPaneOptions = { type: SelectionPanelType.account, selectedIds: null };
		}
	}

	private setSortParameter(sortOption: SortByOption, sortDirection: SortDirection): void {
		this.sortParameter = new SearchParameter(ParameterType.sort, '', sortOption + ',' + sortDirection);
	}

	private setSelectedAccountsFromFilters(filters: IDataOutletFilters): void {
		this.selectedAccountIds = filters.accountId;
		this.selectionPaneOptions = { type: SelectionPanelType.account, selectedIds: this.selectedAccountIds };
	}

	private setTQLFromFilters(filters: IDataOutletFilters): void {
		this.tqlExpression = filters.tql;
	}

	setDateRangeProperties(contextDate?: DateTime): void {
		const rollingDate: DateTime =
			contextDate?.minus({ days: this.dateRange.rollingEndDateOffset || 0 }) ??
			DateTime.now()
				.startOf('day')
				.minus({ days: this.dateRange.rollingEndDateOffset || 0 });
		if (this.dateRange.dateRangeOption === DateRangeOption.dateRange) {
			const calcEndDate: DateTime = this.dateRange.rollingDates
				? rollingDate
				: DateTime.fromISO(this.dateRange.endDate, {
						zone: 'UTC',
					});

			this.dateRange.endDate = calcEndDate.toFormat('yyyy-MM-dd');
			const calcStartDate: DateTime = this.dateRange.rollingDates
				? calcEndDate.minus({ days: this.dateRange.rollingStartDateOffset || 0 })
				: DateTime.fromISO(this.dateRange.startDate);
			this.dateRange.startDate = calcStartDate?.toFormat('yyyy-MM-dd');
			this.dateRange.periods = this.dateRange.usePeriods ? this.dateRange.periods : getPeriods(calcEndDate, calcStartDate, this.dateRange.cadence, false);
		} else if (this.dateRange.dateRangeOption === DateRangeOption.usePeriods) {
			const calcEndDate: DateTime = this.dateRange.rollingDates
				? rollingDate
				: DateTime.fromISO(this.dateRange.endDate, {
						zone: 'UTC',
					});

			const calcStartDate: DateTime = DateTime.fromJSDate(getStartDate(calcEndDate.toJSDate(), this.dateRange.cadence, this.dateRange.periods, false));
			this.dateRange.startDate = calcStartDate?.toFormat('yyyy-MM-dd');
			this.dateRange.endDate = calcEndDate?.toFormat('yyyy-MM-dd');
		}
		this.setOutletDateParameters(contextDate);
	}

	private setOutletDateParameters(contextDate?: DateTime): void {
		this.calendarSettings = new CalendarSettings();
		if (this.dateRange.dateRangeOption === DateRangeOption.previousDay || this.dateRange.dateRangeOption === DateRangeOption.currentDay) {
			let datetime: DateTime = contextDate ?? DateTime.now();

			if (this.dateRange.dateRangeOption === DateRangeOption.previousDay) {
				datetime = datetime.minus({ days: 1 });

				if (this.dateRange.excludeWeekends) {
					// Check if the previous day is a weekend (Saturday or Sunday)
					if (datetime.weekday === 6 /* Saturday */) {
						// If it's Saturday, subtract one more day to skip the weekend
						datetime = datetime.minus({ days: 1 });
					} else if (datetime.weekday === 7 /* Sunday */) {
						// If it's Sunday, subtract two more days to skip the weekend
						datetime = datetime.minus({ days: 2 });
					}
				} else {
					this.dateRange.excludeWeekends = false;
				}
			}
			const formattedDate: string = datetime.toFormat('yyyy-MM-dd');
			const startDateParameter: SearchParameter = this.searchData.parameters.find((param: SearchParameter) => param.type === ParameterType.startDate);
			if (startDateParameter) {
				startDateParameter.value = formattedDate;
				startDateParameter.locked = true;
			} else {
				this.searchData.startDateParameter = new SearchParameter(ParameterType.startDate, formattedDate, formattedDate, true);
			}

			const endDateParameter: SearchParameter = this.searchData.parameters.find((param: SearchParameter) => param.type === ParameterType.endDate);
			if (endDateParameter) {
				endDateParameter.value = formattedDate;
				endDateParameter.locked = true;
			} else {
				this.searchData.endDateParameter = new SearchParameter(ParameterType.endDate, formattedDate, formattedDate, true);
			}
			this.calendarSettings.startDate = formattedDate;
			this.calendarSettings.endDate = formattedDate;
		} else if (this.dateRange.dateRangeOption === DateRangeOption.dateRange || this.dateRange.dateRangeOption === DateRangeOption.usePeriods) {
			delete this.searchData.startDateParameter;
			delete this.searchData.endDateParameter;
			const startDateParameter: SearchParameter = this.searchData.parameters.find((param: SearchParameter) => param.type === ParameterType.startDate);
			const endDateParameter: SearchParameter = this.searchData.parameters.find((param: SearchParameter) => param.type === ParameterType.endDate);
			if (endDateParameter) {
				endDateParameter.value = this.dateRange.endDate;
				endDateParameter.locked = true;
			} else {
				this.searchData.endDateParameter = new SearchParameter(ParameterType.endDate, this.dateRange.endDate, this.dateRange.endDate, true);
			}

			let startDateString: string;
			if (this.dateRange.dateRangeOption === DateRangeOption.usePeriods) {
				const startDate: Date = getStartDate(DateTime.fromISO(this.dateRange.endDate).toJSDate(), this.dateRange.cadence, this.dateRange.periods, false);
				startDateString = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd');
			} else {
				startDateString = this.dateRange.startDate;
			}
			this.calendarSettings.startDate = startDateString;
			this.calendarSettings.endDate = this.dateRange.endDate;
			if (startDateParameter) {
				startDateParameter.value = startDateString;
				startDateParameter.locked = true;
			} else {
				this.searchData.startDateParameter = new SearchParameter(ParameterType.startDate, startDateString, startDateString, true);
			}
		}
	}
}

export enum SortByOption {
	account = 'account',
}

export enum SortDirection {
	asc = 'asc',
	dec = 'dec',
}

export interface IDataOutletFilters {
	tql?: object;
	accountId?: string[];
}

export interface DateRange {
	dateRangeOption: DateRangeOption;
	startDate?: string;
	endDate?: string;
	periods?: number;
	cadence?: Cadence;
	usePeriods?: boolean;
	rollingDates?: boolean;
	rollingEndDateOffset?: number;
	rollingStartDateOffset?: number;
	excludeWeekends?: boolean;
}

export interface WorkbookColumn {
	binding: string;
	customLabel?: string;
	defaultHeader?: string;
	currencyFormatting?: boolean;
	commaFormatting?: boolean;
	hidden?: boolean;
}

export interface RelativeGridPosition {
	isBelow?: boolean;
	isPast?: boolean;
	top: number;
	left: number;
}

export interface LiteralGridPosition {
	colLeft: number;
	colRight: number;
	rowTop: number;
	rowBottom: number;
}

export enum DataSource {
	transactions = 'transactions',
	balances = 'balances',
}

export enum LegacyDataSource {
	transactions = 'transactions',
}

export enum DateRangeOption {
	currentDay = 'currentDay',
	previousDay = 'previousDay',
	dateRange = 'dateRange',
	usePeriods = 'usePeriods',
}

export const dateRangeDisplayOptions: DisplayOption[] = [
	{
		displayValue: 'Current Day',
		key: DateRangeOption.currentDay,
	},
	{
		displayValue: 'Previous Day',
		key: DateRangeOption.previousDay,
	},
	{
		displayValue: 'Date Range',
		key: DateRangeOption.dateRange,
	},
	{
		displayValue: 'Use Periods',
		key: DateRangeOption.usePeriods,
	},
];

export interface AccountBalanceGridItem {
	balances: HistoricalBalance[];
	displayValue: string;
	accountId: string;
	currencyCode: string;
	convertedCurrencyCode: string;
}

export class DisplayOption {
	key: string;
	displayValue: string;
}

export enum OutletEventType {
	edit = 'edit',
	delete = 'delete',
	add = 'add',
}
export class CollisionResult {
	collisionDetected: boolean;
	// collisionWith uses writeOrderIndex to reference colliding outlet
	collisionWith: number;
	isConsumed: boolean;
	minVectorAspect: CollisionAspect;
	maxVectorAspect: CollisionAspect;
	horizontalDepth: number;
	verticalDepth: number;
	private minVector: number;
	private maxVector: number;

	_topEdgeOverlap?: number;
	_leftEdgeOverlap?: number;
	_rightEdgeOverlap?: number;
	_bottomEdgeOverlap?: number;

	set top(value: number) {
		this._topEdgeOverlap = value;
		if (!this.minVectorAspect || value < this.minVector) {
			this.minVectorAspect = CollisionAspect.top;
			this.minVector = value;
		}
		if (!this.maxVectorAspect || value > this.maxVector) {
			this.maxVectorAspect = CollisionAspect.top;
			this.maxVector = value;
		}
	}

	set bottom(value: number) {
		this._bottomEdgeOverlap = value;
		if (!this.minVectorAspect || value < this.minVector) {
			this.minVectorAspect = CollisionAspect.bottom;
			this.minVector = value;
		}
		if (!this.maxVectorAspect || value > this.maxVector) {
			this.maxVectorAspect = CollisionAspect.bottom;
			this.maxVector = value;
		}
	}

	set left(value: number) {
		this._leftEdgeOverlap = value;
		if (!this.minVectorAspect || value < this.minVector) {
			this.minVectorAspect = CollisionAspect.left;
			this.minVector = value;
		}
		if (!this.maxVectorAspect || value > this.maxVector) {
			this.maxVectorAspect = CollisionAspect.left;
			this.maxVector = value;
		}
	}

	set right(value: number) {
		this._rightEdgeOverlap = value;
		if (!this.minVectorAspect || value < this.minVector) {
			this.minVectorAspect = CollisionAspect.right;
			this.minVector = value;
		}
		if (!this.maxVectorAspect || value > this.maxVector) {
			this.maxVectorAspect = CollisionAspect.right;
			this.maxVector = value;
		}
	}

	constructor(literalPosition: LiteralGridPosition, collidingOutlet2: DataOutlet) {
		this.collisionDetected = false;
		this.collisionWith = collidingOutlet2.writeOrderIndex;

		this.setCollisionVectors(literalPosition, collidingOutlet2.literalPosition);
	}

	private setCollisionVectors(literalPosition: LiteralGridPosition, literalPosition2: LiteralGridPosition): void {
		const overlapsLeftEdge: boolean = literalPosition.colLeft <= literalPosition2.colLeft && literalPosition.colRight >= literalPosition2.colLeft;
		const overlapsRightEdge: boolean = literalPosition.colRight >= literalPosition2.colRight && literalPosition.colLeft <= literalPosition2.colRight;
		const overlapsBottomEdge: boolean = literalPosition.rowBottom >= literalPosition2.rowBottom && literalPosition.rowTop <= literalPosition2.rowBottom;
		const overlapsTopEdge: boolean = literalPosition.rowBottom >= literalPosition2.rowTop && literalPosition.rowTop <= literalPosition2.rowTop;
		const overlapsVertically: boolean =
			(literalPosition.rowBottom < literalPosition2.rowBottom && literalPosition.rowTop > literalPosition2.rowTop) || overlapsBottomEdge || overlapsTopEdge;
		const overlapsHorizontally: boolean =
			(literalPosition.colLeft > literalPosition2.colLeft && literalPosition.colRight < literalPosition2.colRight) || overlapsRightEdge || overlapsLeftEdge;
		this.isConsumed =
			literalPosition.rowBottom < literalPosition2.rowBottom &&
			literalPosition.rowTop > literalPosition2.rowTop &&
			literalPosition.colLeft > literalPosition2.colLeft &&
			literalPosition.colRight < literalPosition2.colRight;

		if (overlapsVertically) {
			this.verticalDepth = Math.min(literalPosition.rowBottom, literalPosition2.rowBottom) - Math.max(literalPosition.rowTop, literalPosition2.rowTop) + 1;
		}

		if (overlapsHorizontally) {
			this.horizontalDepth = Math.min(literalPosition.colRight, literalPosition2.colRight) - Math.max(literalPosition.colLeft, literalPosition2.colLeft) + 1;
		}
		if (!this.isConsumed) {
			if (overlapsLeftEdge && overlapsVertically) {
				if (overlapsTopEdge && overlapsBottomEdge) {
					this.left = literalPosition2.rowBottom - literalPosition2.rowTop + 1;
				} else if (overlapsTopEdge) {
					this.left = literalPosition.rowBottom - literalPosition2.rowTop + 1;
				} else if (overlapsBottomEdge) {
					this.left = literalPosition2.rowBottom - literalPosition.rowTop + 1;
				} else {
					this.left = literalPosition.rowBottom - literalPosition.rowTop + 1;
				}
				this.collisionDetected = true;
			}

			if (overlapsRightEdge && overlapsVertically) {
				if (overlapsTopEdge && overlapsBottomEdge) {
					this.right = literalPosition2.rowBottom - literalPosition2.rowTop + 1;
				} else if (overlapsTopEdge) {
					this.right = literalPosition.rowBottom - literalPosition2.rowTop + 1;
				} else if (overlapsBottomEdge) {
					this.right = literalPosition2.rowBottom - literalPosition.rowTop + 1;
				} else {
					this.right = literalPosition.rowBottom - literalPosition.rowTop + 1;
				}
				this.collisionDetected = true;
			}

			if (overlapsTopEdge && overlapsHorizontally) {
				if (overlapsLeftEdge && overlapsRightEdge) {
					this.top = literalPosition2.colRight - literalPosition2.colLeft + 1;
				} else if (overlapsLeftEdge) {
					this.top = literalPosition.colRight - literalPosition2.colLeft + 1;
				} else if (overlapsRightEdge) {
					this.top = literalPosition2.colRight - literalPosition.colLeft + 1;
				} else {
					this.top = literalPosition.colRight - literalPosition.colLeft + 1;
				}
				this.collisionDetected = true;
			}

			if (overlapsBottomEdge && overlapsHorizontally) {
				if (overlapsLeftEdge && overlapsRightEdge) {
					this.bottom = literalPosition2.colRight - literalPosition2.colLeft + 1;
				} else if (overlapsLeftEdge) {
					this.bottom = literalPosition.colRight - literalPosition2.colLeft + 1;
				} else if (overlapsRightEdge) {
					this.bottom = literalPosition2.colRight - literalPosition.colLeft + 1;
				} else {
					this.bottom = literalPosition.colRight - literalPosition.colLeft + 1;
				}
				this.collisionDetected = true;
			}
		} else {
			this.top = literalPosition.rowTop - literalPosition2.rowTop;
			this.bottom = literalPosition2.rowBottom - literalPosition.rowBottom;
			this.left = literalPosition.colLeft - literalPosition2.colLeft;
			this.right = literalPosition2.colRight - literalPosition.colRight;
			this.collisionDetected = true;
		}
	}
}

export const setCollisionVectors = () => {};

export enum CollisionAspect {
	top = 'top',
	bottom = 'bottom',
	right = 'right',
	left = 'left',
}

export enum GridDirection {
	up = 'up',
	down = 'down',
	right = 'right',
	left = 'left',
}
