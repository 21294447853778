import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { CurrentCash, CurrentCashData } from 'src/app/features/balances/models/current-cash.model';
import { CurrentCashService } from 'src/app/features/balances/services/current-cash.service';
import { catchError, combineLatest, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import {
	AddDataToCurrentCashReport,
	ClearCurrentCashState,
	DeleteCurrentCashReport,
	GetCurrentCashReports,
	GetCashPosition,
	InitCurrentCashState,
	ResetCurrentCashState,
	SaveCurrentCashReport,
	UpdateChangedCurrentCashState,
	LazyLoadCurrentCashReport,
} from '../../actions/current-cash.actions';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, CashPositionUpdatedEvent, CurrentCashUpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { PermissionId, PermissionMap } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { SnackType } from '@trovata/app/shared/models/snacks.model';
import { PreferencesFacadeService } from '@trovata/app/shared/services/facade/preferences.facade.service';

export class CurrentCashStateModel extends EntitledStateModel {
	currentCashReports: CurrentCash[];
	cashPosition: number;
	apiInFlight: boolean;
	isError: boolean;
}

@State<CurrentCashStateModel>({
	name: 'currentCash',
	defaults: {
		currentCashReports: null,
		cashPosition: null,
		apiInFlight: null,
		isError: null,
		isCached: false,
	},
})
@Injectable()
export class CurrentCashState {
	@Selector() static currentCashState(state: CurrentCashStateModel): CurrentCashStateModel {
		return state;
	}
	@Selector() static currentCashReports(state: CurrentCashStateModel): CurrentCash[] {
		return state.currentCashReports;
	}
	@Selector() static currentCashReport(state: CurrentCashStateModel, currentCashId: string): CurrentCash {
		return state.currentCashReports ? state.currentCashReports.find(cashReport => cashReport.currentCashId === currentCashId) : undefined;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private preferencesFacadeService: PreferencesFacadeService,
		private serializationService: SerializationService,
		private store: Store,
		private currentCashService: CurrentCashService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitCurrentCashState)
	async initCurrentCashState(context: StateContext<CurrentCashStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const currentCashStateIsCached: boolean = this.currentCashStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (!this.isInitialized && appReady && permissions) {
						if (permissions.has(PermissionId.readCurrentCash)) {
							if (currentCashStateIsCached) {
								const state: CurrentCashStateModel = deserializedState.currentCash;
								context.patchState(state);
							} else {
								context.dispatch(new GetCurrentCashReports());
								context.dispatch(new GetCashPosition());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ currentCashReports: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetCurrentCashReports)
	getCurrentCashReports(context: StateContext<CurrentCashStateModel>): Observable<CurrentCash[]> {
		let state = context.getState();
		if (!state.currentCashReports) {
			state.apiInFlight = true;
			state.isError = false;
			context.patchState(state);
			return this.currentCashService.getCurrentCashReports().pipe(
				tap((currentCashReports: CurrentCash[]) => {
					state = context.getState();
					state.currentCashReports = this.sortCurrCashById(currentCashReports);
					state.apiInFlight = false;
					state.isCached = true;
					context.patchState(state);
					this.lazyLoadVisibleCurrentCashData(context);
				}),
				catchError(error => {
					state = context.getState();
					state.apiInFlight = false;
					state.isError = true;
					context.patchState(state);
					return throwError(() => error);
				})
			);
		}
	}

	@Action(GetCashPosition)
	getCashPosition(context: StateContext<CurrentCashStateModel>): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			try {
				this.currentCashService.getCurrentCashPreview(null).subscribe((currentCashData: CurrentCashData) => {
					if (currentCashData?.summary?.balances[0]?.compositeBalanceConverted) {
						const cashPosition: number = currentCashData.summary.balances[0].compositeBalanceConverted;
						context.patchState({ cashPosition: cashPosition });
						resolve();
					} else {
						reject(new Error('Invalid data for POST /data/current-cash/data'));
					}
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(AddDataToCurrentCashReport)
	addDataToCurrentCashReport(context: StateContext<CurrentCashStateModel>, action: AddDataToCurrentCashReport): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const currentState: CurrentCashStateModel = context.getState();
			const currentCashReports: CurrentCash[] = currentState.currentCashReports.map((currentCash: CurrentCash) =>
				currentCash.currentCashId === action.currentCash.currentCashId ? action.currentCash : currentCash
			);
			if (!currentCashReports.some((currentCash: CurrentCash) => currentCash.currentCashId === action.currentCash.currentCashId)) {
				currentCashReports.push(action.currentCash);
			}
			currentState.currentCashReports = this.sortCurrCashById(currentCashReports);
			context.patchState(currentState);
			resolve();
		});
	}

	@Action(UpdateChangedCurrentCashState)
	updateChangedCurrentCashState(context: StateContext<CurrentCashStateModel>, action: UpdateChangedCurrentCashState): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const dataUpdates: Promise<void>[] = [];
			action.currCashReports.forEach((currCash: CurrentCash) => {
				currCash.hasError = false;
				dataUpdates.push(
					firstValueFrom(this.store.dispatch(new AddDataToCurrentCashReport(currCash))).then(() =>
						this.updatedEventsService.updateItem(new CurrentCashUpdatedEvent(ActionType.update, currCash.currentCashId, currCash))
					)
				);
			});
			return Promise.all(dataUpdates).then(() => resolve());
		});
	}

	@Action(LazyLoadCurrentCashReport)
	lazyLoadCurrentCashReport(context: StateContext<CurrentCashStateModel>, action: LazyLoadCurrentCashReport): void {
		this.lazyLoadCurrentCashData(context, action.currentCashId);
	}

	private sortCurrCashById(currentCashReports: CurrentCash[]): CurrentCash[] {
		return currentCashReports.sort((a: CurrentCash, b: CurrentCash) => {
			if (a.currentCashId < b.currentCashId) {
				return -1;
			} else if (a.currentCashId > b.currentCashId) {
				return 1;
			}
			return 0;
		});
	}

	private lazyLoadCurrentCashData(context: StateContext<CurrentCashStateModel>, currentCashId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: CurrentCashStateModel = context.getState();
			const currentCashToLoad: CurrentCash = state.currentCashReports.find(
				(currentCashReport: CurrentCash) => currentCashReport.currentCashId === currentCashId
			);

			this.currentCashService.getCurrentCashData(currentCashId).subscribe(
				(data: CurrentCashData) => {
					currentCashToLoad.data = data;
					currentCashToLoad.hasError = false;
					this.store.dispatch(new AddDataToCurrentCashReport(currentCashToLoad));
					resolve();
				},
				err => {
					currentCashToLoad.hasError = true;
					this.store.dispatch(new AddDataToCurrentCashReport(currentCashToLoad));
					reject(err);
				}
			);
		});
	}

	private async lazyLoadVisibleCurrentCashData(context: StateContext<CurrentCashStateModel>): Promise<void> {
		const visibleReportIds: string[] = await this.preferencesFacadeService.getVisibleSnackIds(SnackType.currentCash);
		this.getCurrentCashData(context, visibleReportIds);
	}

	private getCurrentCashData(context: StateContext<CurrentCashStateModel>, currentCashIds: string[]): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const state: CurrentCashStateModel = context.getState();
			state.isError = false;
			context.patchState(state);
			const currentCashReportToLoad: CurrentCash[] = state.currentCashReports.filter(cashReport => currentCashIds.includes(cashReport.currentCashId));
			for (let i: number = 0; i < state.currentCashReports.length; i++) {
				const currentCashReport: CurrentCash = currentCashReportToLoad[i];
				try {
					await this.lazyLoadCurrentCashData(context, currentCashReport.currentCashId);
				} catch (error) {
					console.error(error);
				}
			}
			resolve();
		});
	}

	@Action(ResetCurrentCashState)
	resetCurrentCashState(context: StateContext<CurrentCashStateModel>): void {
		context.dispatch(new ClearCurrentCashState());
		context.dispatch(new GetCurrentCashReports());
	}

	@Action(ClearCurrentCashState)
	clearCurrentCashState(context: StateContext<CurrentCashStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: CurrentCashStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(SaveCurrentCashReport)
	saveCurrentCashReport(context: StateContext<CurrentCashStateModel>, action: SaveCurrentCashReport): Observable<any> {
		let request: Observable<CurrentCash>;
		if (action.currentCash?.currentCashId) {
			request = this.currentCashService.editCurrentCash(action.currentCash);
		} else {
			request = this.currentCashService.createCurrentCash(action.currentCash);
		}
		if (request) {
			return request.pipe(
				tap(
					async (cashReport: CurrentCash) => {
						if (cashReport) {
							const state: CurrentCashStateModel = context.getState();
							let currentCashReports: CurrentCash[] = state.currentCashReports;
							if (currentCashReports?.find(currentCash => currentCash.currentCashId === cashReport.currentCashId)) {
								currentCashReports = currentCashReports.map(currentCash => (currentCash.currentCashId === cashReport.currentCashId ? cashReport : currentCash));
								state.currentCashReports = this.sortCurrCashById(currentCashReports);
							} else if (currentCashReports) {
								state.currentCashReports = this.sortCurrCashById([...currentCashReports, cashReport]);
							} else {
								state.currentCashReports = this.sortCurrCashById([cashReport]);
							}
							context.patchState(state);
							await this.getCurrentCashData(context, [cashReport.currentCashId]);
							this.updatedEventsService.updateItem(new CurrentCashUpdatedEvent(ActionType.update, cashReport.currentCashId, cashReport));
						} else {
							return new Error('Error saving current cash report');
						}
					},
					catchError(error => throwError(() => error))
				)
			);
		}
	}

	@Action(DeleteCurrentCashReport)
	deleteCurrentCashReport(context: StateContext<CurrentCashStateModel>, action: DeleteCurrentCashReport) {
		return this.currentCashService.deleteCurrentCashReport(action.currentCashId).subscribe({
			next: () => {
				this.updatedEventsService.updateItem(new CashPositionUpdatedEvent(ActionType.delete, action.currentCashId));
				const state: CurrentCashStateModel = context.getState();
				let currentCashReports: CurrentCash[] = state.currentCashReports;
				if (currentCashReports?.find(currentCash => currentCash.currentCashId === action.currentCashId)) {
					currentCashReports = currentCashReports.filter(currentCash => currentCash.currentCashId !== action.currentCashId);
					state.currentCashReports = currentCashReports;
					context.patchState(state);
				}
				context.dispatch(new GetCurrentCashReports());
			},
			error: error => throwError(() => error),
		});
	}

	private currentCashStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedCurrentCashState: CurrentCashStateModel = deserializedState?.currentCash;
		if (deserializedCurrentCashState?.currentCashReports?.length && deserializedCurrentCashState?.cashPosition && deserializedCurrentCashState?.isCached) {
			return true;
		} else {
			return false;
		}
	}
}
