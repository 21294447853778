import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { ClearPreferencesState, InitPreferencesState, ResetPreferencesState } from '../../actions/preferences.actions';
import { HttpResponse } from '@angular/common/http';
import { GetAllTQLFields, GetValues, InitTQLFieldsState } from '../../actions/tql-fields.actions';
import { TqlService } from '@trovata/app/shared/services/tql.service';
import {
	TQLFieldContext,
	TQLFieldDict,
	TQLFieldsResponse,
	TQLOperatorDict,
	TQLOperatorKey,
	TQLOperatorOption,
	TQLValuesDict,
} from '@trovata/app/shared/models/tql.model';

// temp - API change required
export const notOperator: TQLOperatorOption = {
	displayValue: 'NOT',
	id: TQLOperatorKey.not,
	isLike: null,
	symbolPlacement: null,
};

export const logicalOperators: TQLOperatorOption[] = [
	{
		displayValue: 'AND',
		id: TQLOperatorKey.and,
		isLike: null,
		symbolPlacement: null,
	},
	{
		displayValue: 'OR',
		id: TQLOperatorKey.or,
		isLike: null,
		symbolPlacement: null,
	},
	{
		displayValue: 'AND NOT',
		id: TQLOperatorKey.andNot,
		isLike: null,
		symbolPlacement: null,
	},
	{
		displayValue: 'OR NOT',
		id: TQLOperatorKey.orNot,
		isLike: null,
		symbolPlacement: null,
	},
];
// temp end

export const getStateSliceFromContext = (context: string): string => {
	switch (context) {
		case TQLFieldContext.balancesAnalysis:
			return 'balancesAnalysisTQLFields';
		case TQLFieldContext.transactionsAnalysis:
			return 'transactionsAnalysisTQLFields';
		case TQLFieldContext.transactions:
			return 'transactionsTQLFields';
		case TQLFieldContext.tags:
			return 'tagsTQLFields';
		case TQLFieldContext.glTags:
			return 'glTagsTQLFields';
		case TQLFieldContext.accounts:
			return 'accountsTQLFields';
		case TQLFieldContext.balances:
			return 'balancesTQLFields';
	}
};

export class TQLFieldsStateModel {
	balancesAnalysisTQLFields: TQLFieldDict;
	transactionsAnalysisTQLFields: TQLFieldDict;
	transactionsTQLFields: TQLFieldDict;
	balancesTQLFields: TQLFieldDict;
	accountsTQLFields: TQLFieldDict;
	tagsTQLFields: TQLFieldDict;
	glTagsTQLFields: TQLFieldDict;
	operatorsDict: TQLOperatorDict;
	valuesDict: TQLValuesDict;
}

@State<TQLFieldsStateModel>({
	name: 'tqlFields',
	defaults: {
		balancesAnalysisTQLFields: null,
		transactionsAnalysisTQLFields: null,
		transactionsTQLFields: null,
		balancesTQLFields: null,
		accountsTQLFields: null,
		tagsTQLFields: null,
		glTagsTQLFields: null,
		operatorsDict: null,
		valuesDict: null,
	},
})
@Injectable()
export class TQLFieldsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	@Selector()
	static tqlFields(state: TQLFieldsStateModel): TQLFieldsStateModel {
		return state;
	}

	@Selector()
	static tqlValues(state: TQLFieldsStateModel): TQLValuesDict {
		return state.valuesDict;
	}

	@Selector()
	static tqlOperators(state: TQLFieldsStateModel): TQLOperatorDict {
		return state.operatorsDict;
	}

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private tqlService: TqlService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitTQLFieldsState)
	async initTQLFieldsState(context: StateContext<TQLFieldsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const tqlFieldsStateIsCached: boolean = this.tqlFieldsStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (tqlFieldsStateIsCached && appReady) {
						const state: TQLFieldsStateModel = deserializedState.tqlFields;
						context.patchState(state);
					} else if (!tqlFieldsStateIsCached && appReady) {
						context.dispatch(new GetAllTQLFields());
						context.dispatch(new GetValues());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetAllTQLFields)
	getAllTQLFields(context: StateContext<TQLFieldsStateModel>, action: GetAllTQLFields): void {
		combineLatest([
			this.tqlService.getTQLFields(TQLFieldContext.transactionsAnalysis),
			this.tqlService.getTQLFields(TQLFieldContext.balancesAnalysis),
			this.tqlService.getTQLFields(TQLFieldContext.transactions),
			this.tqlService.getTQLFields(TQLFieldContext.tags),
			this.tqlService.getTQLFields(TQLFieldContext.glTags),
			this.tqlService.getTQLFields(TQLFieldContext.accounts),
			this.tqlService.getTQLFields(TQLFieldContext.balances),
		]).subscribe({
			next: ([
				transactionAnalysisResponse,
				balanceAnalysisResponse,
				transactionsResponse,
				tagsResponse,
				glTagsResponse,
				accountsResponse,
				balancesResponse,
			]: HttpResponse<TQLFieldsResponse>[]) => {
				const state: TQLFieldsStateModel = context.getState();
				if (!state.operatorsDict) {
					state.operatorsDict = transactionAnalysisResponse.body.operators;
					// temp - API change required
					state.operatorsDict[notOperator.id] = notOperator;
					logicalOperators.forEach((logicalOperator: TQLOperatorOption) => {
						state.operatorsDict[logicalOperator.id] = logicalOperator;
					});
				}
				state.balancesAnalysisTQLFields = balanceAnalysisResponse.body.fields;
				state.transactionsAnalysisTQLFields = transactionAnalysisResponse.body.fields;
				state.transactionsTQLFields = transactionsResponse.body.fields;
				state.tagsTQLFields = tagsResponse.body.fields;
				state.glTagsTQLFields = glTagsResponse.body.fields;
				state.accountsTQLFields = accountsResponse.body.fields;
				state.balancesTQLFields = balancesResponse.body.fields;
				context.patchState(state);
			},
			error: error => throwError(() => error),
		});
	}

	@Action(GetValues)
	getAllValues(context: StateContext<TQLFieldsStateModel>, action: GetValues): void {
		this.tqlService.getTQLValues([]).subscribe({
			next: (valuesResponse: HttpResponse<any>) => {
				const state: TQLFieldsStateModel = context.getState();
				const dict: TQLValuesDict = valuesResponse.body.fieldsWithValues;
				if (state.valuesDict && action.fields.length) {
					action.fields.forEach((field: string) => {
						state.valuesDict[field] = dict[field];
					});
				} else {
					state.valuesDict = dict;
				}
				context.patchState(state);
			},
			error: error => throwError(() => error),
		});
	}

	@Action(ResetPreferencesState)
	resetPreferencesState(context: StateContext<TQLFieldsStateModel>) {
		context.dispatch(new ClearPreferencesState());
		context.dispatch(new InitPreferencesState());
	}

	@Action(ClearPreferencesState)
	clearPreferencesState(context: StateContext<TQLFieldsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: TQLFieldsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private tqlFieldsStateIsCached(deserializedState: TrovataAppState | undefined): boolean {
		if (deserializedState && deserializedState.preferences) {
			// fix for weird headless cypress test bug
			const deserializedTQLFieldsState: TQLFieldsStateModel = deserializedState.tqlFields;
			if (
				deserializedTQLFieldsState.transactionsAnalysisTQLFields &&
				deserializedTQLFieldsState.balancesAnalysisTQLFields &&
				deserializedTQLFieldsState.transactionsTQLFields &&
				deserializedTQLFieldsState.tagsTQLFields &&
				deserializedTQLFieldsState.balancesTQLFields &&
				deserializedTQLFieldsState.accountsTQLFields &&
				deserializedTQLFieldsState.operatorsDict &&
				deserializedTQLFieldsState.valuesDict
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}
