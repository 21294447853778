import { DEFAULT_ENVIRONMENT_CONFIG, DefaultEnvironment } from '@trovata/app/core/models/config.model';

export const environment: DefaultEnvironment = {
	...DEFAULT_ENVIRONMENT_CONFIG,
	environmentName: 'nabdev',
	apiSuffix: '-dev',
	defaultCurrency: 'AUD',
	production: false,
	beta: false,
	development: true,
	productionToggles: false,
	plaidRedirectUri: 'https://app.trovata.io/plaid-oauth',
	stripe: 'dev',
	sideNavIconPath: 'src/assets/svgs/active-icons/nab-icons',
	logoPath: 'src/assets/images/environments/nab',
	edgeApi: 'https://edge.dev.nab.trovata.io/',
	trovataApi: 'https://api.dev.nab.trovata.io/',
	workspaceApi: 'https://api.dev.nab.trovata.io/workspace',
	edgeAPI: DEFAULT_ENVIRONMENT_CONFIG.edgeAPI,
	trovataAPI: DEFAULT_ENVIRONMENT_CONFIG.trovataAPI,
	workspaceAPI: DEFAULT_ENVIRONMENT_CONFIG.workspaceAPI,
	auth0Domain: 'auth.nab.trovata.io',
	auth0Audience: 'workspace',
	auth0ClientId: 'beYo1LqyYhZK9zf9TqwDRD2hFreP4Da8',
	intercomAppId: 'ez2iu8qx',
	ssoTestApp: 'https://sso-test.dev.nab.trovata.io?logout=true&env=nab',
};
