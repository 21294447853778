import { ChangeRequest } from 'src/app/shared/models/admin-approval.model';
import { of } from 'rxjs';
import { Address, PaymentsAccount, UsState, UsStateCode } from './account.model';
import { TemplateRow } from './paginated-templates-table-view.model';
import { CustomMenuOption } from 'src/app/shared/models/custom-menu.model';
import { Country, CountryCode } from '@trovata/app/shared/utils/country-data';

export interface GetTemplatesResponse {
	count: number;
	rows: Template[];
}

export interface Template {
	beneficiary: Beneficiary;
	beneficiaryBank: BeneficiaryBank;
	intermediaryBank: Intermediary;
	changeRequest: ChangeRequest | null;
	name: string;
	templateId: string;
	type: PaymentType;
	country: string;
	needsReview?: boolean;
	account?: PaymentsAccount;
	amount?: number;
}

export interface InstitutionFieldsDict {
	[index: string]: InstitutionPaymentTypes;
}

export interface InstitutionFieldsResponse {
	institutions: InstitutionFieldsDict;
}

export interface InstitutionPaymentTypes {
	ach: InstitutionPaymentTypeFields;
	rtp: InstitutionPaymentTypeFields;
	transfer: InstitutionPaymentTypeFields;
	wire: InstitutionPaymentTypeFields;
}

export interface InstitutionPaymentTypeFields {
	beneficiaryAddressRequired: boolean;
	enabled: boolean;
}

export interface InternationalPaymentsCountryResponse {
	countries: InternationalPaymentsCountryDict;
}
export interface InternationalPaymentsCountryDict {
	[index: string]: InternationalPaymentsCountry;
}

export enum BankNumberType {
	accountNumber = 'accountNumber',
	routingNumber = 'routingNumber',
	iban = 'iban',
	bic = 'bic',
}
export interface InternationalPaymentsCountry {
	accountNumberType: string; // account number / iban
	bankIdType: string; // routing number / bic
}

export interface TemplateToCreate {
	beneficiary: Beneficiary;
	beneficiaryBank: BeneficiaryBank;
	intermediaryBank: Intermediary;
	country: string;
	name: string;
	type: PaymentType;
}

export interface Beneficiary {
	accountId?: string;
	accountNumber?: string;
	accountName?: string;
	address: Address;
	name: string;
	iban?: string;
}

export interface BeneficiaryBank {
	address: Address;
	country: string;
	name: string;
	routingNumber?: string;
	bic?: string;
}

export interface Intermediary {
	address: Address;
	country: string;
	name: string;
	routingNumber?: string;
	bic?: string;
	accountNumber?: string;
	iban?: string;
}

export enum PaymentType {
	WIRE = 'wire',
	ACH = 'ach',
	RTP = 'rtp',
	TRANSFER = 'transfer',
	VARIABLE = 'variable',
}

export enum TemplateAction {
	CREATE = 'create',
	EDIT = 'edit',
}

export interface CreateEditTemplateFormValues {
	identifier?: string;
	name: string;
	method: PaymentType;
	beneficiaryName: string;
	accountNumber: string;
	country: Country;
	state: UsState;
	city: string;
	street: string;
	streetLineTwo: string;
	zip: string;
	bankName: string;
	routingNumber: string;
	bankCountry: Country;
	bankState: UsState;
	bankCity: string;
	bankStreet: string;
	bankStreetLineTwo: string;
	bankZip: string;
}

export interface TemplateToDelete {
	templateId: string;
}

export const PAYMENT_TYPES: PaymentType[] = [PaymentType.WIRE, PaymentType.ACH, PaymentType.RTP, PaymentType.TRANSFER];

export interface RequiredFields {
	[key: string]: boolean;
}

// !!TODO!! this should be using the standard custom menu event
export interface TemplateMenuEvent {
	option: CustomMenuOption;
	row: TemplateRow;
}

export interface PayFromStepFormValue {
	account: PaymentsAccount;
	paymentType: PaymentType;

	name?: string;
	amount?: string;
}

export interface SetupStepFormValue {
	name: string;
}

export interface PaymentDetailsStepFormValue {
	paymentType: PaymentType;
}

export interface PayToStepFormValue {
	beneficiaryName: string;
	beneficiaryAccountNumber: string;
	beneficiaryIBAN: string;
	beneficiaryStreet: string;
	beneficiaryStreetTwo: string;
	beneficiaryCity: string;
	beneficiaryState: string;
	beneficiaryZip: string;
	beneficiaryCountry: CountryCode;

	beneficiaryBankName: string;
	beneficiaryBankRoutingNumber: string;
	beneficiaryBankBIC: string;
	beneficiaryBankStreet: string;
	beneficiaryBankStreetTwo: string;
	beneficiaryBankCity: string;
	beneficiaryBankState: string;
	beneficiaryBankZip: string;
	beneficiaryBankCountry: CountryCode;

	intermediaryName: string;
	intermediaryAccountNumber: string;
	intermediaryIBAN: string;
	intermediaryRoutingNumber: string;
	intermediaryBIC: string;
	intermediaryStreet: string;
	intermediaryStreetTwo: string;
	intermediaryCity: string;
	intermediaryState: string;
	intermediaryZip: string;
	intermediaryCountry: CountryCode;
}

export const MOCK_BENEFICIARY_BANK: BeneficiaryBank = {
	country: CountryCode.US,
	name: 'test',
	routingNumber: 'test',
	address: {
		street: 'test',
		city: 'test',
		state: UsStateCode.CA,
		streetLineTwo: 'test',
		zip: 'test',
		country: CountryCode.US,
	},
};

export const MOCK_BENEFICIARY: Beneficiary = {
	name: 'test',
	accountNumber: 'test',
	address: {
		street: 'test',
		city: 'test',
		state: UsStateCode.CA,
		streetLineTwo: 'test',
		zip: 'test',
		country: CountryCode.US,
	},
};

export const MOCK_INTERMEDIARY: Intermediary = {
	name: 'test',
	bic: 'test',
	iban: 'test',
	country: 'USA',
	address: {
		street: 'test',
		city: 'test',
		state: UsStateCode.CA,
		streetLineTwo: 'test',
		zip: 'test',
		country: CountryCode.US,
	},
};

export const MOCK_TEMPLATE: Template = {
	beneficiary: MOCK_BENEFICIARY,
	beneficiaryBank: MOCK_BENEFICIARY_BANK,
	intermediaryBank: MOCK_INTERMEDIARY,
	changeRequest: null,
	country: 'Yugoslavia',
	templateId: 'test',
	name: 'test',
	type: PaymentType.WIRE,
};

export const MOCK_TEMPLATES: Template[] = [MOCK_TEMPLATE];

export const MOCK_TEMPLATES_SERVICE = {
	getTemplates: () => of(MOCK_TEMPLATES),
	createTemplate: (body: Template) => of(MOCK_TEMPLATE),
	updateTemplate: (body: Template) => of(MOCK_TEMPLATE),
	deleteTemplate: (body: TemplateToDelete) => of({}),
};

export const MOCK_TEMPLATE_TO_DELETE: TemplateToDelete = {
	templateId: 'test',
};
