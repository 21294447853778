import { FilterMap, FilterOption } from 'src/app/shared/models/abstract-filter.model';
import { CurrentCash } from '../../balances/models/current-cash.model';
import { CashPosition } from '../../cash-position/models/cash-position.model';
import { IForecast } from '../../forecasts/models/forecast-forecast-response.model';
import { IStream } from '../../forecasts/models/forecast-stream.model';
import { ReconciliationReport } from '../../reports/models/reconciliation.model';
import { ReportV4 } from '../../reports/models/report.model';
import { Tag } from '../../transactions/models/tag.model';
import { ParameterType } from '../../transactions/models/transaction-search-parameter.model';
import { Effect, Feature, Permission } from './feature.model';
import { CustomerUser } from './identity.model';
import { ChangeRequestV2 } from 'src/app/shared/models/admin-approval.model';
import { Entity } from '../../entities/models/entity.model';
import { IWorkbook } from '../../workbooks/models/workbook.model';
import { GLTag } from '../../transactions/models/glTag.model';

export interface UserGroupResourceItem {
	resourceId: string;
	name?: string;
	added?: boolean;
	removed?: boolean;
}

export interface GetResourceTypesResponse {
	resourceTypes: UserGroupResourceType[];
	totalResourceTypes: number;
}

export interface UserGroupResourceType {
	resourceTypeId: UserGroupResourceTypeId;
	name: string;
}

export enum UserGroupResourceTypeId {
	account = 1,
	accountTag = 2,
	report = 5,
	forecast = 6,
	tag = 7,
	gLTag = 8,
	reconReport = 9,
	workbook = 10,
	forecastStream = 11,
	currentCash = 12,
	cashPosition = 16,
	entities = 17,
}

export const ResourceTypeTitle: (type: UserGroupResourceTypeId) => string = (type: UserGroupResourceTypeId) => {
	switch (type) {
		case UserGroupResourceTypeId.account:
			return 'accounts';
		case UserGroupResourceTypeId.forecast:
			return 'forecasts';
		case UserGroupResourceTypeId.report:
			return 'reports';
		case UserGroupResourceTypeId.tag:
			return 'tags';
		case UserGroupResourceTypeId.forecastStream:
			return 'forecast streams';
		case UserGroupResourceTypeId.currentCash:
			return 'current cash reports';
		case UserGroupResourceTypeId.cashPosition:
			return 'cash position reports';
		case UserGroupResourceTypeId.entities:
			return 'entities';
	}
};

export interface UserGroupResourceData {
	resourceId: string;
	resourceType: UserGroupResourceTypeId;
	name: string;
}

export interface UserGroupResourceMap {
	[resourceTypeId: number]: UserGroupResourceData[];
}

export const getUserGroupResourceMap: (
	filters: FilterMap,
	forecasts: IForecast[],
	forecastStreams: IStream[],
	reports: ReportV4[],
	recons: ReconciliationReport[],
	currCashReports: CurrentCash[],
	tags: Tag[],
	glTags: GLTag[],
	cashPositions: CashPosition[],
	entities: Entity[],
	workbooks: IWorkbook[]
) => UserGroupResourceMap = (
	filters: FilterMap,
	forecasts: IForecast[],
	forecastStreams: IStream[],
	reports: ReportV4[],
	recons: ReconciliationReport[],
	currCashReports: CurrentCash[],
	tags: Tag[],
	glTags: GLTag[],
	cashPositions: CashPosition[],
	entities: Entity[],
	workbooks: IWorkbook[]
) => {
	const map: UserGroupResourceMap = {};
	if (filters) {
		map[UserGroupResourceTypeId.account] = filters[ParameterType.accountId].options.map((opt: FilterOption) => ({
			resourceId: opt.id,
			name: opt.displayValue,
			resourceType: UserGroupResourceTypeId.account,
		}));
		map[UserGroupResourceTypeId.accountTag] = [...filters['entity'].options, ...filters['region'].options, ...filters['division'].options].map(
			(opt: FilterOption) => ({
				resourceId: opt.id,
				name: opt.displayValue,
				resourceType: UserGroupResourceTypeId.accountTag,
			})
		);
	}
	if (reports) {
		map[UserGroupResourceTypeId.report] = reports.map((report: ReportV4) => ({
			resourceId: report.reportId,
			name: report.name,
			resourceType: UserGroupResourceTypeId.report,
		}));
	}
	if (forecasts) {
		map[UserGroupResourceTypeId.forecast] = forecasts.map((forecast: IForecast) => ({
			resourceId: forecast.forecastId,
			name: forecast.name,
			resourceType: UserGroupResourceTypeId.forecast,
		}));
	}
	if (forecastStreams) {
		map[UserGroupResourceTypeId.forecastStream] = forecastStreams.map((forecastStream: IStream) => ({
			resourceId: forecastStream.streamId,
			name: forecastStream.name,
			resourceType: UserGroupResourceTypeId.forecastStream,
		}));
	}
	if (tags) {
		map[UserGroupResourceTypeId.tag] = tags.map((tag: Tag) => ({
			resourceId: tag.tagId,
			name: tag.tagTitle,
			resourceType: UserGroupResourceTypeId.tag,
		}));
	}
	if (glTags) {
		map[UserGroupResourceTypeId.gLTag] = glTags.map((tag: GLTag) => ({
			resourceId: tag.glTagId,
			name: tag.title,
			resourceType: UserGroupResourceTypeId.tag,
		}));
	}
	if (recons) {
		map[UserGroupResourceTypeId.reconReport] = recons.map((recon: ReconciliationReport) => ({
			resourceId: recon.reconId,
			name: recon.name,
			resourceType: UserGroupResourceTypeId.reconReport,
		}));
	}
	if (currCashReports) {
		map[UserGroupResourceTypeId.currentCash] = currCashReports.map((currCash: CurrentCash) => ({
			resourceId: currCash.currentCashId,
			name: currCash.name,
			resourceType: UserGroupResourceTypeId.currentCash,
		}));
	}
	if (cashPositions) {
		map[UserGroupResourceTypeId.cashPosition] = cashPositions.map((cashPosition: CashPosition) => ({
			resourceId: cashPosition.cashPositioningId,
			name: cashPosition.name,
			resourceType: UserGroupResourceTypeId.cashPosition,
		}));
	}
	if (entities) {
		map[UserGroupResourceTypeId.entities] = entities.map((entity: Entity) => ({
			resourceId: entity.entityId,
			name: entity.nickname || entity.name,
			resourceType: UserGroupResourceTypeId.entities,
		}));
	}
	if (workbooks) {
		map[UserGroupResourceTypeId.workbook] = workbooks.map((workbook: IWorkbook) => ({
			resourceId: workbook.workbookId?.toString() || '',
			name: workbook.name,
			resourceType: UserGroupResourceTypeId.workbook,
		}));
	}
	return map;
};

export interface UserGroupResource {
	resourceTypeId: UserGroupResourceTypeId;
	name: string;
	fullAccess: boolean;
	resources: UserGroupResourceItem[];
}

export interface GetUserGroupsResponse {
	totalUserGroups: number;
	userGroups: UserGroup[];
}

export class UserGroup {
	userGroupId: number;
	name: string;
	description: string;
	isTrovataUserGroup: boolean;
	requiresPeerReview: boolean;
	trovataMetadata: UserGroupMetadata;
	resources: UserGroupResource[];
	features: Feature[];
	users: UserGroupUser[];
	changeRequest?: ChangeRequestV2;
}

export interface UserGroupUser {
	userId: string;
	firstName: string;
	lastName: string;
	added?: boolean;
	removed?: boolean;
}

export interface AllowedFeaturesOrPermissions {
	allowed: number[];
	denied: number[];
	noEffect?: number[];
}

export interface FeaturePermissionConfig {
	featureIds: AllowedFeaturesOrPermissions;
	permissionIds: AllowedFeaturesOrPermissions;
}

export enum UserGroupResourceSelectType {
	resources = 'resources',
	resourceGroups = 'resourceGroups',
}

export class UserGroupPostBody {
	name?: string;
	description?: string;
	userIds?: string[];
	resources?: UserGroupResource[];
	featureIds?: AllowedFeaturesOrPermissions;
	permissionIds?: AllowedFeaturesOrPermissions;

	constructor(group: UserGroup) {
		const visitedFeaturePermissions: FeaturePermissionConfig = visitFeaturePermissions(group);
		this.permissionIds = {
			allowed: visitedFeaturePermissions.permissionIds.allowed,
			denied: visitedFeaturePermissions.permissionIds.denied,
		};
		this.featureIds = {
			allowed: visitedFeaturePermissions.featureIds.allowed,
			denied: visitedFeaturePermissions.featureIds.denied,
		};
		this.name = group.name;
		this.description = group.description;
		this.userIds = group.users.map((user: UserGroupUser) => user.userId);
		this.resources = group.resources;
	}
}

export interface EmittedUserGroupPostBody {
	body: UserGroupPostBody;
	valid: boolean;
}

export const visitFeaturePermissions: (featurePermission: Feature | UserGroup, config?: FeaturePermissionConfig) => FeaturePermissionConfig = (
	featurePermission: Feature | UserGroup,
	config: FeaturePermissionConfig = {
		featureIds: { allowed: [], denied: [], noEffect: [] },
		permissionIds: { allowed: [], denied: [], noEffect: [] },
	}
) => {
	if ('featureId' in featurePermission) {
		featurePermission.permissions?.forEach((permission: Permission) => {
			if (permission.effect === Effect.allow) {
				config.permissionIds.allowed.push(permission.permissionId);
			} else if (permission.effect === Effect.deny) {
				config.permissionIds.denied.push(permission.permissionId);
			} else {
				config.permissionIds.noEffect.push(permission.permissionId);
			}
		});
		if (featurePermission.effect === Effect.allow) {
			config.featureIds.allowed.push(featurePermission.featureId);
		} else if (featurePermission.effect === Effect.deny) {
			config.featureIds.denied.push(featurePermission.featureId);
		} else {
			config.featureIds.noEffect.push(featurePermission.featureId);
		}
	}
	featurePermission.features?.forEach(feature => (config = visitFeaturePermissions(feature, config)));
	return config;
};

export interface UserGroupMetadata {
	arePermissionsEditable: boolean;
	areResourcesEditable: boolean;
	areUsersEditable: boolean;
	hasPrivateResourceAccess: boolean;
	userLimit: number;
}

export interface ManageGroupsDialogEvent {
	user: CustomerUser;
	userGroups: UserGroup[];
}

export interface UserGroupPermissionMap {
	featureIds: Map<number, Effect>;
	permissionIds: Map<number, Effect>;
}

export enum AccountOwnerControlledGroupName {
	// These names will never be allowed to change
	accountOwner = 'Account Owner',
	peerReviewers = 'Peer Reviewers',
}

export interface ReviewPermissionPanel {
	name: string;
	changed: boolean;
	allowedEffect: boolean;
	deniedEffect: boolean;
	effectCount: number;
	changedCount: number;
	featurePanels: ReviewPermissionPanel[];
	permissionPanels: ReviewPermissionPanel[];
}

export interface ReviewResourcePanel {
	name: string;
	changedCount: number;
	fullAccess: boolean;
	resourceTypeId: UserGroupResourceTypeId;
	resources: UserGroupResourceItem[];
}

export interface ReviewUsersSection {
	name: string;
	changedCount: number;
	users: UserGroupUser[];
}

export interface ResourceAccessUser {
	email: string;
	name: string;
	userId?: string;
	access: 'allow' | 'deny';
}
