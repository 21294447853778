import { ChangeRequest } from 'src/app/shared/models/admin-approval.model';
import { of } from 'rxjs';
import { PaymentsAccountRow } from './paginated-accounts-table-view.model';
import { CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';
import { COUNTRIES, Country, CountryCode } from '@trovata/app/shared/utils/country-data';
import { environment } from '@trovata/environments/environment';

export interface GetPaymentsAccountsResponse {
	accounts: PaymentsAccount[];
}

export interface PaymentsAccount {
	accountId: string;
	accountNumber: string;
	achCompanyId: boolean;
	achEnabled: boolean;
	address: Address;

	companyName: string;
	customerId: string;
	institution: InstitutionId;
	name: string;
	routingNumber: string;
	rtpEnabled: boolean;
	transferEnabled: boolean;
	wireEnabled: boolean;
	currency: string;
	balance?: AccountBalance;
	bic?: string;
	iban?: string;
	workflow?: AccountWorkflow;
	changeRequest?: ChangeRequest;
	institutionInstruction?: InstitutionInstruction;
	authUserCanInitiatePayment?: boolean;
	needsReview?: boolean;
}

export interface Address {
	city: string;
	country: CountryCode;
	state: string;
	street: string;
	streetLineTwo: string;
	zip: string;

	id?: number;
	name?: string;
}

export class AccountWorkflow {
	workflowId: string;
	name: string;
}

export class AccountBalance {
	asOfDate: string;
	currentDay: boolean;
	endingAvailableAmount: number;
	endingLedgerAmount: number;
	openingAvailableAmount: number;
	openingLedgerAmount: number;
}

export interface UsState {
	stateName: UsStateName;
	stateCode: UsStateCode;
}

export interface CountryCodeDict {
	[key: string]: CountryCode;
}

export interface AccountFormValues {
	name: string;
	companyName: string;
	accountNumber: string;
	routingNumber: string;
	workflow: AccountWorkflow;
	institution: string;
	country: Country;
	state: UsState;
	city: string;
	street: string;
	streetLineTwo: string;
	zip: string;
}

export interface AccountToUpdate {
	accountId: string;
	name: string;
	companyName: string;
	accountNumber: string;
	routingNumber: string;
	workflowId: string;
	institution: string;
	address: Address;
}

export interface InstitutionInstructions {
	identifier: string;
	BOFA: InstitutionInstruction;
	JPM: InstitutionInstruction;
	PNC: InstitutionInstruction;
	SVB: InstitutionInstruction;
	WFB: InstitutionInstruction;
}

export interface InstitutionInstruction {
	ach: InstructionPath[];
	rtp: InstructionPath[];
	transfer: InstructionPath[];
	wire: InstructionPath[];
}

export enum InstructionPath {
	BENEFICIARY_NAME = 'beneficiary.name',
	BENEFICIARY_ACCOUNT_NUMBER = 'beneficiary.accountNumber',
	BENEFICIARY_STATE = 'beneficiary.address.state',
	BENEFICIARY_ZIP = 'beneficiary.address.zip',
	BENEFICIARY_STREET = 'beneficiary.address.street',
	BENEFICIARY_CITY = 'beneficiary.address.city',
	BENEFICIARY_COUNTRY = 'beneficiary.address.country',
	BENEFICIARY_BANK_NAME = 'beneficiaryBank.name',
	BENEFICIARY_BANK_ROUTING_NUMBER = 'beneficiaryBank.routingNumber',
	BENEFICIARY_BANK_STATE = 'beneficiaryBank.address.state',
	BENEFICIARY_BANK_ZIP = 'beneficiaryBank.address.zip',
	BENEFICIARY_BANK_STREET = 'beneficiaryBank.address.street',
	BENEFICIARY_BANK_CITY = 'beneficiaryBank.address.city',
	BENEFICIARY_BANK_COUNTRY = 'beneficiaryBank.address.country',
}

export enum InstitutionId {
	BOFA = 'BOFA',
	JPM = 'JPM',
	WFB = 'WFB',
	PNC = 'PNC',
	SVB = 'SVB',
}

export interface PaymentsAccountMenuEvent {
	option: CustomMenuOption;
	row: PaymentsAccountRow;
}

export enum UsStateName {
	ALABAMA = 'Alabama',
	ALASKA = 'Alaska',
	ARIZONA = 'Arizona',
	ARKANSAS = 'Arkansas',
	CALIFORNIA = 'California',
	COLORADO = 'Colorado',
	CONNECTICUT = 'Connecticut',
	DELAWARE = 'Delaware',
	DISTRICT_OF_COLUMBIA = 'District of Columbia',
	FLORIDA = 'Florida',
	GEORGIA = 'Georgia',
	HAWAII = 'Hawaii',
	IDAHO = 'Idaho',
	ILLINOIS = 'Illinois',
	INDIANA = 'Indiana',
	IOWA = 'Iowa',
	KANSAS = 'Kansas',
	KENTUCKY = 'Kentucky',
	LOUISIANA = 'Louisiana',
	MAINE = 'Maine',
	MARYLAND = 'Maryland',
	MASSACHUSETTS = 'Massachusetts',
	MICHIGAN = 'Michigan',
	MINNESOTA = 'Minnesota',
	MISSISSIPPI = 'Mississippi',
	MISSOURI = 'Missouri',
	MONTANA = 'Montana',
	NEBRASKA = 'Nebraska',
	NEVADA = 'Nevada',
	NEW_HAMPSHIRE = 'New Hampshire',
	NEW_JERSEY = 'New Jersey',
	NEW_MEXICO = 'New Mexico',
	NEW_YORK = 'New York',
	NORTH_CAROLINA = 'North Carolina',
	NORTH_DAKOTA = 'North Dakota',
	OHIO = 'Ohio',
	OKLAHOMA = 'Oklahoma',
	OREGON = 'Oregon',
	PENNSYLVANIA = 'Pennsylvania',
	RHODE_ISLAND = 'Rhode Island',
	SOUTH_CAROLINA = 'South Carolina',
	SOUTH_DAKOTA = 'South Dakota',
	TENNESSEE = 'Tennessee',
	TEXAS = 'Texas',
	UTAH = 'Utah',
	VERMONT = 'Vermont',
	VIRGINIA = 'Virginia',
	WASHINGTON = 'Washington',
	WEST_VIRGINIA = 'West Virginia',
	WISCONSIN = 'Wisconsin',
	WYOMING = 'Wyoming',
}

export enum UsStateCode {
	AL = 'AL',
	AK = 'AK',
	AZ = 'AZ',
	AR = 'AR',
	CA = 'CA',
	CO = 'CO',
	CT = 'CT',
	DE = 'DE',
	DC = 'DC',
	FL = 'FL',
	GA = 'GA',
	HI = 'HI',
	ID = 'ID',
	IL = 'IL',
	IN = 'IN',
	IA = 'IA',
	KS = 'KS',
	KY = 'KY',
	LA = 'LA',
	ME = 'ME',
	MD = 'MD',
	MA = 'MA',
	MI = 'MI',
	MN = 'MN',
	MS = 'MS',
	MO = 'MO',
	MT = 'MT',
	NE = 'NE',
	NV = 'NV',
	NH = 'NH',
	NJ = 'NJ',
	NM = 'NM',
	NY = 'NY',
	NC = 'NC',
	ND = 'ND',
	OH = 'OH',
	OK = 'OK',
	OR = 'OR',
	PA = 'PA',
	RI = 'RI',
	SC = 'SC',
	SD = 'SD',
	TN = 'TN',
	TX = 'TX',
	UT = 'UT',
	VT = 'VT',
	VA = 'VA',
	WA = 'WA',
	WV = 'WV',
	WI = 'WI',
	WY = 'WY',
}

export const US_STATES: UsState[] = [
	{ stateName: UsStateName.ALABAMA, stateCode: UsStateCode.AL },
	{ stateName: UsStateName.ALASKA, stateCode: UsStateCode.AK },
	{ stateName: UsStateName.ARIZONA, stateCode: UsStateCode.AZ },
	{ stateName: UsStateName.ARKANSAS, stateCode: UsStateCode.AR },
	{ stateName: UsStateName.CALIFORNIA, stateCode: UsStateCode.CA },
	{ stateName: UsStateName.COLORADO, stateCode: UsStateCode.CO },
	{ stateName: UsStateName.CONNECTICUT, stateCode: UsStateCode.CT },
	{ stateName: UsStateName.DELAWARE, stateCode: UsStateCode.DE },
	{ stateName: UsStateName.DISTRICT_OF_COLUMBIA, stateCode: UsStateCode.DC },
	{ stateName: UsStateName.FLORIDA, stateCode: UsStateCode.FL },
	{ stateName: UsStateName.GEORGIA, stateCode: UsStateCode.GA },
	{ stateName: UsStateName.HAWAII, stateCode: UsStateCode.HI },
	{ stateName: UsStateName.IDAHO, stateCode: UsStateCode.ID },
	{ stateName: UsStateName.ILLINOIS, stateCode: UsStateCode.IL },
	{ stateName: UsStateName.INDIANA, stateCode: UsStateCode.IN },
	{ stateName: UsStateName.IOWA, stateCode: UsStateCode.IA },
	{ stateName: UsStateName.KANSAS, stateCode: UsStateCode.KS },
	{ stateName: UsStateName.KENTUCKY, stateCode: UsStateCode.KY },
	{ stateName: UsStateName.LOUISIANA, stateCode: UsStateCode.LA },
	{ stateName: UsStateName.MAINE, stateCode: UsStateCode.ME },
	{ stateName: UsStateName.MARYLAND, stateCode: UsStateCode.MD },
	{ stateName: UsStateName.MASSACHUSETTS, stateCode: UsStateCode.MA },
	{ stateName: UsStateName.MICHIGAN, stateCode: UsStateCode.MI },
	{ stateName: UsStateName.MINNESOTA, stateCode: UsStateCode.MN },
	{ stateName: UsStateName.MISSISSIPPI, stateCode: UsStateCode.MS },
	{ stateName: UsStateName.MISSOURI, stateCode: UsStateCode.MO },
	{ stateName: UsStateName.MONTANA, stateCode: UsStateCode.MT },
	{ stateName: UsStateName.NEBRASKA, stateCode: UsStateCode.NE },
	{ stateName: UsStateName.NEVADA, stateCode: UsStateCode.NV },
	{ stateName: UsStateName.NEW_HAMPSHIRE, stateCode: UsStateCode.NH },
	{ stateName: UsStateName.NEW_JERSEY, stateCode: UsStateCode.NJ },
	{ stateName: UsStateName.NEW_MEXICO, stateCode: UsStateCode.NM },
	{ stateName: UsStateName.NEW_YORK, stateCode: UsStateCode.NY },
	{ stateName: UsStateName.NORTH_CAROLINA, stateCode: UsStateCode.NC },
	{ stateName: UsStateName.NORTH_DAKOTA, stateCode: UsStateCode.ND },
	{ stateName: UsStateName.OHIO, stateCode: UsStateCode.OH },
	{ stateName: UsStateName.OKLAHOMA, stateCode: UsStateCode.OK },
	{ stateName: UsStateName.OREGON, stateCode: UsStateCode.OR },
	{ stateName: UsStateName.PENNSYLVANIA, stateCode: UsStateCode.PA },
	{ stateName: UsStateName.RHODE_ISLAND, stateCode: UsStateCode.RI },
	{ stateName: UsStateName.SOUTH_CAROLINA, stateCode: UsStateCode.SC },
	{ stateName: UsStateName.SOUTH_DAKOTA, stateCode: UsStateCode.SD },
	{ stateName: UsStateName.TENNESSEE, stateCode: UsStateCode.TN },
	{ stateName: UsStateName.TEXAS, stateCode: UsStateCode.TX },
	{ stateName: UsStateName.UTAH, stateCode: UsStateCode.UT },
	{ stateName: UsStateName.VERMONT, stateCode: UsStateCode.VT },
	{ stateName: UsStateName.VIRGINIA, stateCode: UsStateCode.VA },
	{ stateName: UsStateName.WASHINGTON, stateCode: UsStateCode.WA },
	{ stateName: UsStateName.WEST_VIRGINIA, stateCode: UsStateCode.WV },
	{ stateName: UsStateName.WISCONSIN, stateCode: UsStateCode.WI },
	{ stateName: UsStateName.WYOMING, stateCode: UsStateCode.WY },
];

export const MOCK_ADDRESS: Address = {
	state: UsStateCode.CA,
	street: 'test',
	streetLineTwo: 'test',
	city: 'test',
	zip: 'test',
	country: CountryCode.US,
};

export const MOCK_ACCOUNT_WORKFLOW: AccountWorkflow = {
	workflowId: 'test',
	name: 'test',
};

export const MOCK_ACCOUNT_BALANCE: AccountBalance = {
	asOfDate: 'test',
	currentDay: true,
	endingAvailableAmount: 0,
	endingLedgerAmount: 0,
	openingAvailableAmount: 0,
	openingLedgerAmount: 0,
};

export const MOCK_ACCOUNT: PaymentsAccount = {
	accountId: 'test',
	accountNumber: 'test',
	achCompanyId: true,
	achEnabled: true,
	address: MOCK_ADDRESS,
	balance: MOCK_ACCOUNT_BALANCE,
	companyName: 'test',
	customerId: 'test',
	currency: environment.defaultCurrency,
	institution: InstitutionId.BOFA,
	name: 'test',
	routingNumber: 'test',
	workflow: MOCK_ACCOUNT_WORKFLOW,
	rtpEnabled: true,
	transferEnabled: true,
	wireEnabled: true,
};

export const MOCK_ACCOUNTS: PaymentsAccount[] = [MOCK_ACCOUNT];

export const MOCK_INSTITUTION_INSTRUCTION: InstitutionInstruction = {
	ach: [InstructionPath.BENEFICIARY_NAME],
	rtp: [InstructionPath.BENEFICIARY_NAME],
	transfer: [InstructionPath.BENEFICIARY_NAME],
	wire: [InstructionPath.BENEFICIARY_NAME],
};

export const MOCK_INSTITUTION_INSTRUCTIONS: InstitutionInstructions = {
	identifier: 'test',
	BOFA: MOCK_INSTITUTION_INSTRUCTION,
	JPM: MOCK_INSTITUTION_INSTRUCTION,
	PNC: MOCK_INSTITUTION_INSTRUCTION,
	SVB: MOCK_INSTITUTION_INSTRUCTION,
	WFB: MOCK_INSTITUTION_INSTRUCTION,
};

export const MOCK_ACCOUNT_TO_UPDATE: AccountToUpdate = {
	accountId: MOCK_ACCOUNT.accountId,
	name: MOCK_ACCOUNT.name,
	companyName: MOCK_ACCOUNT.companyName,
	accountNumber: MOCK_ACCOUNT.accountNumber,
	routingNumber: MOCK_ACCOUNT.routingNumber,
	workflowId: MOCK_ACCOUNT.workflow.workflowId,
	institution: MOCK_ACCOUNT.institution,
	address: {
		state: MOCK_ACCOUNT.address.state,
		street: MOCK_ACCOUNT.address.street,
		streetLineTwo: MOCK_ACCOUNT.address.streetLineTwo,
		city: MOCK_ACCOUNT.address.city,
		zip: MOCK_ACCOUNT.address.zip,
		country: MOCK_ACCOUNT.address.country,
	},
};

export const MOCK_ACCOUNTS_SERVICE = {
	getAccounts: () => of(MOCK_ACCOUNTS),
	updateAccount: () => of(MOCK_ACCOUNT_TO_UPDATE),
};

export const MOCK_ACCOUNT_FORM_VALUES: AccountFormValues = {
	name: 'test',
	companyName: 'test',
	accountNumber: 'test',
	routingNumber: 'test',
	workflow: MOCK_ACCOUNT_WORKFLOW,
	institution: 'test',
	country: COUNTRIES[0],
	state: US_STATES[0],
	city: 'test',
	street: 'test',
	streetLineTwo: 'test',
	zip: 'test',
};
