import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { PaymentsAccount, Address, UsState, InstitutionId, MOCK_ADDRESS } from './account.model';
import { Beneficiary, BeneficiaryBank, Intermediary, MOCK_BENEFICIARY, MOCK_BENEFICIARY_BANK, Template, PaymentType } from './template.model';
import {
	PaymentStatus,
	ApprovalUser,
	StageRuleDetail,
	MOCK_PAYMENT_EXECUTION_STAGE,
	WorkflowStageRuleCondition,
	WorkflowStageRuleVariable,
} from './workflow.model';
import { CountryCode } from '@trovata/app/shared/utils/country-data';
import { FlagIconSize, getFlagIconURLByCode } from '@trovata/app/shared/utils/country-flag-svg-helper';

export interface PaymentsListBody {
	from?: number;
	size?: number;
	paymentId?: string[];
	excludeAccountId?: string[];
	batchId?: string[] | string;
	assigned?: boolean;
}

export interface AccountsListBody {
	from?: number;
	size?: number;
	includeBalance?: boolean;
	accountId?: string[];
	excludeAccountId?: string[];
}

export interface Payment {
	account: PaymentDetailAccount;
	amount: number;
	confirmationId: string;
	developerInitiated: boolean;
	history: PaymentHistory[];
	initiated: string;
	initiator: string | null;
	institution: InstitutionId;
	instructions: PaymentInstructions;
	name: string;
	paymentId: string;
	remittance: string;
	status: PaymentStatus;
	source: PaymentSource;
	submission: PaymentSubmission;
	template: PaymentTemplate | null;
	type: PaymentType;
	valueDate: string | null;

	settledCurrency?: string;
	settledAmount?: number;
	currency?: string;
	iban?: string;
	bic?: string;
	isActionRequired?: boolean;
	needsReview?: boolean;
	stageNeedingReview?: PaymentExecutionStage;
	execution?: PaymentExecution;
	customerId?: string;
	executionId?: string;
	submit?: boolean;
	batchId?: string;
	batch?: PaymentsBatchInfo;
	attachments?: PaymentAttachment[];
}

export interface PaymentAttachment {
	attachmentId: string;
	fileName: string;
	fileType: string;
	link: string;
}

export interface PaymentsBatchInfo {
	batchId: string;
	name: string;
	status: PaymentStatus;
}

export interface PaymentTemplate {
	templateId: string;
	name: string;
}

export interface PaymentSubmission {
	completed: boolean;
	completedTimestamp: null;
	confirmationId: string;
	endToEnd: string;
	institutionBatchId: string;
	institutionPaymentId: string;
	institutionSecondaryPaymentId: string;
	institutionStatus: string;
	submitted: boolean;
	submittedTimeStamp: null;
}

export interface PaymentDetailAccount {
	accountId: string;
	accountNumber?: string;
	achCompanyId: string;
	address: Address;
	companyName: string;
	institutionId: InstitutionId;
	name: string;
	routingNumber?: string;
	workflowId: string;
	bic?: string;
	iban?: string;
	currency: string;
	country: string;
}

export interface BatchPaymentDetailAccount {
	accountId: string;
	accountNumber: string;
	achCompanyId: string;
	achEnabled: boolean;
	address: Address;
	companyName: string;
	customerId: string;
	institutionId: InstitutionId;
	name: string;
	routingNumber: string;
	rtpEnabled: boolean;
	transferEnabled: boolean;
	wireEnabled: boolean;
	workflow: BatchPaymentWorkflow;
	workflowId: string;
}

export interface BatchPaymentWorkflow {
	workflowId: string;
	name: string;
}

export interface PaymentExecution {
	name: string;
	stages: PaymentExecutionStage[];

	workflowId?: string;
}

export interface PaymentExecutionStage {
	approval: PaymentExecutionStageApproval;
	name: string;
	rules: PaymentExecutionStageRule[];
	signatures: PaymentExecutionStageSignature[];
	status: PaymentStatus;
	stageId: string;

	needAuthUserApproval?: boolean;
	stageRuleDetails?: StageRuleDetail[];
	uiIndex?: number;
}

export interface PaymentExecutionStageApproval {
	numApprovals: number;
	userIds: string[];
	users: ApprovalUser[];
}

export interface PaymentExecutionStageRule {
	approval: PaymentExecutionStageRuleApproval;
	condition: WorkflowStageRuleCondition;
	numericConstant: number;
	variable: WorkflowStageRuleVariable;

	required?: boolean;
}

export interface PaymentExecutionStageRuleApproval {
	numApprovals: number;
	userIds: string[];

	users?: ApprovalUser[];
}

export interface PaymentExecutionStageSignature {
	comment: string;
	email: string;
	familyName: string;
	givenName: string;
	timestamp: string;
	userId: string;
}

export interface PaymentInstructions {
	beneficiary: Beneficiary;
	beneficiaryBank: BeneficiaryBank;
	intermediaryBank: Intermediary;
	source: PaymentInstructionsSource;
}

export interface BatchPaymentInstructions {
	beneficiary: BatchPaymentInstructionsBeneficiary;
	beneficiaryBank: BatchPaymentInstructionsBeneficiaryBank;
}

export interface BatchPaymentInstructionsBeneficiary {
	accountNumber: string;
	name: string;

	address?: Address;
}

export interface BatchPaymentInstructionsBeneficiaryBank {
	routingNumber: string;

	address?: Address;
}

export interface PaymentInstructionsSource {
	name: string;
	type: string;
}
export interface PaymentHistory {
	comment: string;
	description: string;
	metadata: PaymentHistoryMetaData;
	timestamp: string;
}

export interface PaymentHistoryMetaData {
	userId: string;
	mfaVerified?: boolean;
}

export interface PaymentToCreate {
	name: string;
	remittance: string;
	type: PaymentType;
	templateId: string;
	accountId: string;
	currency: string;
	country: string;
	beneficiaryAccountId: string;
	instructions: PaymentToCreateInstructions;
	amount: number;
	valueDate: string;
}

export interface InitialTransferPayment extends PaymentToCreate {
	beneficiaryAccountId: string;
}

export interface InitialTemplatePayment extends PaymentToCreate {
	templateId: string;
}

export interface InitialManualPayment extends PaymentToCreate {
	instructions: PaymentToCreateInstructions;
}

export interface PaymentToCreateInstructions {
	beneficiary: BeneficiaryDetails;
	beneficiaryBank: BeneficiaryBankDetails;
	intermediaryBank: IntermediaryBankDetails;
}

export interface BeneficiaryDetails {
	name: string;
	accountNumber?: string;
	iban?: string;
	address: Address;
}

export interface BeneficiaryBankDetails {
	name: string;
	country: string;
	routingNumber?: string;
	bic?: string;
	address: Address;
}

export interface IntermediaryBankDetails {
	name: string;
	country: string;
	routingNumber?: string;
	bic?: string;
	accountNumber?: string;
	iban?: string;
	address: Address;
}

export interface AccountInfoFormValues {
	account: PaymentsAccount;
	amount: number;
}

export interface TemplateInfoFormValues {
	manual: boolean;
	template: Template;
}

export interface BeneficiaryFormValues {
	saveAsTemplate: boolean;
	manualTemplateName: string;
	name: string;
	accountNumber: string;
	routingNumber: string;
	type: string;
	street: string;
	streetLineTwo: string;
	city: string;
	state: UsState;
	zip: string;
	country: CountryCode;
	bankName: string;
	bankStreet: string;
	bankStreetLineTwo: string;
	bankCity: string;
	bankState: UsState;
	bankZip: string;
	bankCountry: CountryCode;
}

export interface TransferAccountFormValues {
	account: PaymentsAccount;
}

export interface Signature {
	email: string;
	familyName: string;
	givenName: string;
	timestamp: string;
	userId: string;
}

export enum StageFeedbackStatus {
	APPROVE = 'approve',
	CHANGE = 'change',
	REJECT = 'reject',
}

export interface StageFeedback {
	stageId: string;
	type: StageFeedbackStatus;

	paymentId?: string;
	batchId?: string;
	comment?: string;
	refreshToken?: string;
	otp?: string;
}

export interface PaymentSubmissionOrCancelation {
	paymentId?: string;
	batchId?: string;
}

export interface StageFeedbackFormValues {
	stage: PaymentExecutionStage;
	comment: string;
	feedbackType: string;
}

export enum MarketingVideoPageVideoSrc {
	paymentsPage = '//play.vidyard.com/uVYFykbyREqp5biQ3KfMHm.html?',
	paymentPage = '//play.vidyard.com/uVYFykbyREqp5biQ3KfMHm.html?',
	accountsPage = '//play.vidyard.com/nfv1SbPwvQDYXEKi716m1q.html?',
	createEditWorkflowPage = '//play.vidyard.com/WgusL1h3CCSRtnyXqE2JmM.html?',
	payPage = '//play.vidyard.com/Xybhv3JqNJiNbLnWtT19x8.html?',
	templatesPage = '//play.vidyard.com/A9xEPawsPypwAhvpE5viKM.html?',
	usersPage = '//play.vidyard.com/3ocq2VssC1iq4DHGMKLEHR.html?',
	workflowsPage = '//play.vidyard.com/WgusL1h3CCSRtnyXqE2JmM.html?',
}

export interface ValidateBatchCsvFileResponse {
	columns?: ValidateBatchCsvFileResponseColumn[];
	payments?: BatchsPayment[];
	errors?: BatchLevelError[];
}

export interface LexisNexisBankInformationResponse {
	bankAddress: BankAddress;
	bankIdentifier: string;
	bankIdentifierType: string;
	bankName: string;
	bic?: string;
	countryCode: CountryCode;
	retrievedAt: string;
	rountingNumber?: string;
	sortCode: string;
	status: string;
}

export interface BankAddress {
	branch: string;
	city: string;
	country: string;
	state: string;
	street: string;
	streetLineTwo: string;
	zip: string;
}

export interface BatchLevelError {
	message: string;
	name: string;
	status: number;
}

export interface BatchsPaymentLevelError {
	fieldName: string;
	message: string;
	name: string;
	status: number;
}

export interface ValidateBatchCsvFileResponseColumn {
	fieldName: string;
	label: string;
}

export interface GetBatchPaymentsResponse {
	batches: BatchPayment[];
}

export interface GetPaymentsV1Response {
	payments: Payment[];
}

export interface BatchPayment {
	batchId: string;
	name: string;
	status: PaymentStatus;
	amount: number;
	developerInitiated: boolean;
	// workflowId: string; // TODO when api is ready
	initiated: string;
	valueDate: string | null;
	initiator: string;
	releaser: string;
	execution: PaymentExecution;
	history: PaymentHistory[];
	stats: BatchPaymentStats;
	submission: BatchPaymentSubmission;
	source: PaymentSource;
	currency?: string;
	needsReview?: boolean;
	stageNeedingReview?: PaymentExecutionStage;
	paymentType?: PaymentType;
	remittance?: string;
	// international fields below (currently unused)
	settledCurrency?: string;
	settledAmount?: number;
	confirmationId?: string;
}

export interface BatchPaymentStats {
	completedAmount: number;
	openAmount: number;
	completedPayments: number;
	openPayments: number;
	rejectedPayments: number;
	totalPayments: number;
}

export interface BatchPaymentSubmission {
	completed: boolean;
	completedTimestamp: string;
	submitted: boolean;
	submittedTimestamp: string;
}

export enum ActiveWorkflowStage {
	review = 'review',
	approve = 'approve',
	release = 'release',
}

export enum PaymentSource {
	webManual = 'web_manual',
	developerApi = 'developer_api',
	sftpUpload = 'sftp_upload',
}

export interface BatchsPayment {
	name: string;
	amount: number;
	developerInitiated: boolean;
	initiated: string;
	initiator: string;
	institution: InstitutionId;
	remittance: string;
	status: PaymentStatus;
	type: PaymentType;
	valueDate: string | null;
	account: BatchPaymentDetailAccount;
	execution: PaymentExecution | null;
	history: PaymentHistory[] | null;
	instructions: BatchPaymentInstructions;
	submission: PaymentSubmission | null;
	template: PaymentTemplate | null;

	errors?: BatchsPaymentLevelError[];
}

export interface PaymentStats {
	status: PaymentStatsStatus;
	type: PaymentStatsType;
	breakdown: [PaymentStatsDay];
}

export interface PaymentStatsStatus {
	open: number;
	approved: number;
	submitted: number;
	completed: number;
	canceled: number;
	rejected: number;
	rejectedByBank: number;
}

export interface PaymentStatsType {
	rtp: number;
	wire: number;
	ach: number;
	transfer: number;
}

export interface PaymentStatsDay {
	date: string;
	total: number;
}

export interface AllowedPaymentType {
	paymentType: PaymentType;
	label: string;

	checked?: boolean;
	disabled?: boolean;
}

export interface InternationalWireCountry {
	name: string;
	countryCode: string;
	flagUrl: string;
}

export const internationalWireCountries: InternationalWireCountry[] = [
	{
		name: 'Australia',
		countryCode: CountryCode.AU,
		flagUrl: getFlagIconURLByCode(CountryCode.AU, FlagIconSize.fourByThree),
	},
	{
		name: 'Canada',
		countryCode: CountryCode.CA,
		flagUrl: getFlagIconURLByCode(CountryCode.CA, FlagIconSize.fourByThree),
	},
	{
		name: 'Czech Republic',
		countryCode: CountryCode.CZ,
		flagUrl: getFlagIconURLByCode(CountryCode.CZ, FlagIconSize.fourByThree),
	},
	{
		name: 'Denmark',
		countryCode: CountryCode.DK,
		flagUrl: getFlagIconURLByCode(CountryCode.DK, FlagIconSize.fourByThree),
	},
	{
		name: 'France',
		countryCode: CountryCode.FR,
		flagUrl: getFlagIconURLByCode(CountryCode.FR, FlagIconSize.fourByThree),
	},
	{
		name: 'Germany',
		countryCode: CountryCode.DE,
		flagUrl: getFlagIconURLByCode(CountryCode.DE, FlagIconSize.fourByThree),
	},
	{
		name: 'India',
		countryCode: CountryCode.IN,
		flagUrl: getFlagIconURLByCode(CountryCode.IN, FlagIconSize.fourByThree),
	},
	{
		name: 'Ireland',
		countryCode: CountryCode.IE,
		flagUrl: getFlagIconURLByCode(CountryCode.IE, FlagIconSize.fourByThree),
	},
	{
		name: 'Italy',
		countryCode: CountryCode.IT,
		flagUrl: getFlagIconURLByCode(CountryCode.IT, FlagIconSize.fourByThree),
	},
	{
		name: 'Japan',
		countryCode: CountryCode.JP,
		flagUrl: getFlagIconURLByCode(CountryCode.JP, FlagIconSize.fourByThree),
	},
	{
		name: 'Netherlands',
		countryCode: CountryCode.NL,
		flagUrl: getFlagIconURLByCode(CountryCode.NL, FlagIconSize.fourByThree),
	},
	{
		name: 'New Zealand',
		countryCode: CountryCode.NZ,
		flagUrl: getFlagIconURLByCode(CountryCode.NZ, FlagIconSize.fourByThree),
	},
	{
		name: 'Norway',
		countryCode: CountryCode.NO,
		flagUrl: getFlagIconURLByCode(CountryCode.NO, FlagIconSize.fourByThree),
	},
	{
		name: 'Poland',
		countryCode: CountryCode.PL,
		flagUrl: getFlagIconURLByCode(CountryCode.PL, FlagIconSize.fourByThree),
	},
	{
		name: 'Singapore',
		countryCode: CountryCode.SG,
		flagUrl: getFlagIconURLByCode(CountryCode.SG, FlagIconSize.fourByThree),
	},
	{
		name: 'Spain',
		countryCode: CountryCode.ES,
		flagUrl: getFlagIconURLByCode(CountryCode.ES, FlagIconSize.fourByThree),
	},
	{
		name: 'Switzerland',
		countryCode: CountryCode.CH,
		flagUrl: getFlagIconURLByCode(CountryCode.CH, FlagIconSize.fourByThree),
	},
	{
		name: 'Sweden',
		countryCode: CountryCode.SE,
		flagUrl: getFlagIconURLByCode(CountryCode.SE, FlagIconSize.fourByThree),
	},
	{
		name: 'United Kingdom',
		countryCode: CountryCode.GB,
		flagUrl: getFlagIconURLByCode(CountryCode.GB, FlagIconSize.fourByThree),
	},
	{
		name: 'United States',
		countryCode: CountryCode.US,
		flagUrl: getFlagIconURLByCode(CountryCode.US, FlagIconSize.fourByThree),
	},
];

export const MOCK_VALIDATION_BATCH_CSV_FILE_RESPONSE_COLUMN: ValidateBatchCsvFileResponseColumn = {
	fieldName: 'account.accountNumber',
	label: 'From Account Number',
};

export const MOCK_BATCH_PAYMENTS: BatchPayment[] = [
	{
		batchId: 'f2bf2990-a0dd-482b-9547-4f3dbbcaec6b',
		name: 'Mock Batch Payment',
		status: PaymentStatus.APPROVED,
		amount: 4000,
		source: PaymentSource.developerApi,
		developerInitiated: false,
		// workflowId: '51b812c7-31f9-48db-8e9b-0eecc6c70549',
		initiated: DateTime.now().toLocaleString(),
		valueDate: DateTime.now().plus({ days: 3 }).toLocaleString(),
		initiator: 'f2bf2990-a0dd-482b-9547-4f3dbbcaec6c',
		releaser: 'g2bf2990-a0dd-482b-9547-4f3dbbcaec6c',
		execution: {
			name: 'Workflow name',
			stages: [
				{
					stageId: 'string',
					name: 'Preparer',
					approval: {
						numApprovals: 0,
						userIds: ['string'],
						users: [
							{
								userId: 'string',
								email: 'string',
								familyName: 'string',
								givenName: 'string',
							},
						],
					},
					rules: [
						{
							condition: WorkflowStageRuleCondition.GREATER_THAN,
							numericConstant: 0,
							variable: WorkflowStageRuleVariable.PAYMENT_AMOUNT,
							approval: {
								numApprovals: 0,
								userIds: ['string'],
								users: [
									{
										userId: 'string',
										email: 'string',
										familyName: 'string',
										givenName: 'string',
									},
								],
							},
						},
					],
					status: PaymentStatus.APPROVED,
					signatures: [
						{
							userId: 'string',
							email: 'string',
							familyName: 'string',
							givenName: 'string',
							comment: '',
							timestamp: 'string',
						},
					],
				},
			],
		},
		history: [
			{
				comment: 'string',
				description: 'string',
				metadata: {
					userId: 'string',
				},
				timestamp: DateTime.now().toLocaleString(),
			},
		],
		stats: {
			completedAmount: 10567,
			completedPayments: 30,
			openPayments: 5,
			rejectedPayments: 0,
			totalPayments: 30,
			openAmount: 1350,
		},
		submission: {
			completed: true,
			completedTimestamp: DateTime.now().toLocaleString(),
			submitted: true,
			submittedTimestamp: DateTime.now().toLocaleString(),
		},
	},
];

export const MOCK_GET_PAYMENT_BATCHES_RESPONSE: GetBatchPaymentsResponse = {
	batches: MOCK_BATCH_PAYMENTS,
};

export const MOCK_STAGE_FEEDBACK_FORM_VALUES: StageFeedbackFormValues = {
	stage: MOCK_PAYMENT_EXECUTION_STAGE,
	comment: 'test',
	feedbackType: StageFeedbackStatus.APPROVE,
};

export const MOCK_PAYMENT_HISTORY: PaymentHistory = {
	comment: 'test',
	description: 'test',
	metadata: { userId: 'test' },
	timestamp: '2021-06-01T19:05:20.250Z',
};

export const MOCK_PAYMENT_EXECUTION: PaymentExecution = {
	name: 'test',
	stages: [],
	workflowId: 'test',
};

export const MOCK_PAYMENT_INSTRUCTIONS_SOURCE: PaymentInstructionsSource = {
	name: 'template',
	type: 'Apple Template',
};

export const MOCK_PAYMENT_INSTRUCTIONS: PaymentInstructions = {
	source: MOCK_PAYMENT_INSTRUCTIONS_SOURCE,
	beneficiary: MOCK_BENEFICIARY,
	intermediaryBank: null,
	beneficiaryBank: MOCK_BENEFICIARY_BANK,
};

export const MOCK_PAYMENT_DETAIL_ACCOUNT: PaymentDetailAccount = {
	accountId: 'test',
	accountNumber: 'test',
	achCompanyId: 'test',
	address: MOCK_ADDRESS,
	companyName: 'test',
	institutionId: InstitutionId.BOFA,
	name: 'test',
	routingNumber: 'test',
	workflowId: 'test',
	currency: 'USD',
	country: 'US',
};

export const MOCK_PAYMENT_SUBMISSION: PaymentSubmission = {
	completed: false,
	completedTimestamp: null,
	confirmationId: '',
	endToEnd: '',
	institutionBatchId: '',
	institutionPaymentId: '',
	institutionSecondaryPaymentId: '',
	institutionStatus: '',
	submitted: false,
	submittedTimeStamp: null,
};

export const MOCK_PAYMENT: Payment = {
	account: MOCK_PAYMENT_DETAIL_ACCOUNT,
	amount: 1.0,
	confirmationId: '',
	developerInitiated: false,
	execution: MOCK_PAYMENT_EXECUTION,
	history: [MOCK_PAYMENT_HISTORY],
	initiated: DateTime.now().toISO(),
	initiator: 'f2bf2990-a0dd-482b-9547-4f3dbbcaec6b',
	institution: InstitutionId.JPM,
	source: PaymentSource.developerApi,
	instructions: MOCK_PAYMENT_INSTRUCTIONS,
	name: 'Mock Payment',
	paymentId: 'f2bf2990-a0dd-482b-9547-4f3dbbcaec6c',
	remittance: 'Remittance',
	status: PaymentStatus.APPROVED,
	submission: MOCK_PAYMENT_SUBMISSION,
	template: null,
	type: PaymentType.ACH,
	valueDate: DateTime.now().plus({ days: 3 }).toISO(),
};

export const MOCK_PAYMENTS: Payment[] = [MOCK_PAYMENT];

export const MOCK_VALIDATION_BATCH_CSV_FILE_RESPONSE: ValidateBatchCsvFileResponse = {
	columns: [MOCK_VALIDATION_BATCH_CSV_FILE_RESPONSE_COLUMN],
	payments: [],
};

export const MOCK_PAYMENT_STATS: PaymentStats = {
	status: {
		open: 0,
		approved: 0,
		submitted: 0,
		completed: 0,
		canceled: 0,
		rejected: 0,
		rejectedByBank: 0,
	},
	type: {
		rtp: 0,
		wire: 0,
		ach: 0,
		transfer: 0,
	},
	breakdown: [
		{
			date: 'test',
			total: 0,
		},
	],
};

export const MOCK_PAYMENTS_SERVICE = {
	getPayments: () => of(MOCK_PAYMENTS),
	initiatePayment: (body: PaymentToCreate) => of(MOCK_PAYMENT),
	setStageFeedback: (body: StageFeedback) => of('test'),
	submitPayment: (body: PaymentSubmissionOrCancelation) => of(MOCK_PAYMENT),
	cancelPayment: (body: PaymentSubmissionOrCancelation) => of(MOCK_PAYMENT),
};
