import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { catchError, combineLatest, Observable, Subscription, tap, throwError } from 'rxjs';
import { PermissionId, PermissionMap } from '../../models/feature.model';
import { CurrentUser, Customer, EditCurrentUserResponse, SSOConnection } from '../../models/identity.model';
import { IdentityService } from '../../services/identity.service';
import { GetCustomerUsers } from '../actions/customer-users.actions';
import { LinkAccountsStatus } from '../../models/identity.model';
import {
	ClearIdentityState,
	GetCustomer,
	InitIdentityState,
	ResetIdentityState,
	EditCurrentUser,
	GetCurrentUser,
	EditCustomer,
	SetLinkAccountsStatus,
	GetSSOConnection,
	EditSSOConnection,
	CreateSSOConnection,
	DeleteSSOConnection,
} from '../actions/identity.actions';
import { CustomerFeatureState } from './customer-feature.state';
import { HeapAnalyticsService } from '@trovata/app/core/services/heap-analytics.service';

export class IdentityStateModel {
	customer: Customer;
	currentUser: CurrentUser;
	ssoConnection: SSOConnection;
	linkAccountsStatus: LinkAccountsStatus;
}

@State<IdentityStateModel>({
	name: 'identity',
	defaults: {
		customer: null,
		currentUser: null,
		ssoConnection: null,
		linkAccountsStatus: null,
	},
})
@Injectable()
export class IdentityState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;
	userAvailablePermissions: PermissionMap;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private identityService: IdentityService,
		private heapService: HeapAnalyticsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static customer(state: IdentityStateModel): Customer {
		return state.customer;
	}

	@Selector()
	static currentUser(state: IdentityStateModel): CurrentUser {
		return state.currentUser;
	}

	@Selector()
	static linkAccountsStatus(state: IdentityStateModel): LinkAccountsStatus {
		return state.linkAccountsStatus;
	}

	@Selector()
	static ssoConnection(state: IdentityStateModel): SSOConnection {
		return state.ssoConnection;
	}

	@Action(InitIdentityState)
	async initIdentityState(context: StateContext<IdentityStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const identityStateIsCached: boolean = this.identityStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (appReady && permissions) {
						this.userAvailablePermissions = permissions;
						if (identityStateIsCached) {
							context.patchState({
								customer: deserializedState.identity.customer,
								currentUser: deserializedState.identity.currentUser,
								linkAccountsStatus: deserializedState.identity.linkAccountsStatus,
								ssoConnection: deserializedState.identity.ssoConnection,
							});
						} else {
							context.dispatch(new GetCustomer());
							context.dispatch(new GetCurrentUser());
							context.dispatch(new GetSSOConnection());
							context.dispatch(
								new SetLinkAccountsStatus({
									linkAccountsRequested: false,
									loggedInService: '',
								})
							);
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(SetLinkAccountsStatus)
	getLinkAccountsStatus(context: StateContext<IdentityStateModel>, action: SetLinkAccountsStatus) {
		const state: IdentityStateModel = context.getState();
		action.linkAccountsStatus.loggedInService = action.linkAccountsStatus?.loggedInService || state.linkAccountsStatus?.loggedInService;
		state.linkAccountsStatus = action.linkAccountsStatus;
		context.patchState(state);
	}

	@Action(GetCustomer)
	getCustomer(context: StateContext<IdentityStateModel>) {
		return this.identityService.getCustomer().pipe(
			tap((customer: Customer) => {
				const state: IdentityStateModel = context.getState();
				state.customer = customer;
				context.patchState(state);
				this.heapService.addUserProperties({ customer: customer.name });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(GetCurrentUser)
	getCurrentUser(context: StateContext<IdentityStateModel>) {
		return this.identityService.getCurrentUser().pipe(
			tap((currentUser: CurrentUser) => {
				const state: IdentityStateModel = context.getState();
				state.currentUser = currentUser;
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(GetSSOConnection)
	getSSOConnection(context: StateContext<IdentityStateModel>) {
		return this.identityService.getSSOConnection().pipe(
			tap((ssoConnection: SSOConnection) => {
				context.patchState({ ssoConnection: ssoConnection });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CreateSSOConnection)
	createSSOConnection(context: StateContext<IdentityStateModel>, action: CreateSSOConnection) {
		return this.identityService.createSSOConnection(action.ssoConnection).pipe(
			tap((ssoConnection: SSOConnection) => {
				context.patchState({ ssoConnection: ssoConnection });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(EditCustomer)
	editCustomer(context: StateContext<IdentityStateModel>, action: EditCustomer) {
		return this.identityService.editCustomer(action.editCustomerBody).pipe(
			tap((editCustomerResponse: Customer) => {
				const state: IdentityStateModel = context.getState();
				state.customer = editCustomerResponse;
				context.patchState(state);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(EditCurrentUser)
	editCurrentUser(context: StateContext<IdentityStateModel>, action: EditCurrentUser) {
		return this.identityService.editCurrentUser(action.editCurrentUserBody).pipe(
			tap((editCurrentUserResponse: EditCurrentUserResponse) => {
				const state: IdentityStateModel = context.getState();
				state.currentUser = {
					...state.currentUser,
					...editCurrentUserResponse,
				};
				context.patchState(state);
				if (this.userAvailablePermissions.has(PermissionId.readUsers)) {
					context.dispatch(new GetCustomerUsers());
				}
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(EditSSOConnection)
	editSSOConnection(context: StateContext<IdentityStateModel>, action: EditSSOConnection) {
		return this.identityService.updateSSOConnection(action.ssoConnection).pipe(
			tap((ssoConnection: SSOConnection) => {
				context.patchState({ ssoConnection: ssoConnection });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(DeleteSSOConnection)
	deleteSSOConnection(context: StateContext<IdentityStateModel>) {
		const state: IdentityStateModel = context.getState();
		return this.identityService.deleteSSOConnection().pipe(
			tap(() => {
				context.patchState({ ssoConnection: null });
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ClearIdentityState)
	clearIdentityState(context: StateContext<IdentityStateModel>) {
		this.appReadySub.unsubscribe();
		const state: IdentityStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetIdentityState)
	resetIdentityState(context: StateContext<IdentityStateModel>) {
		context.dispatch(new GetCustomer());
	}

	private identityStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState?.identity?.customer && deserializedState?.identity?.currentUser && deserializedState?.identity?.ssoConnection) {
			return true;
		} else {
			return false;
		}
	}
}
